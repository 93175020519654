var searchRedesign = {
    ui: {
        event: {
            pageGlobalSearch: function () {
                var windowSize = 0;
                var selector = {
                    mainContainer: '.js-search-container',
                    eventsContainer: '.js-events',
                    toggleSearchFields: '.js-toggle-search-fields',
                    toggleAccordionIcon: "c-toggle-accordion-icon",
                    resetContainer: ".c-es__reset-container",
                    extendedSearchContainer: ".c-es__container",
                    inputText: "input:text",
                    selectField: ".js-location-input",
                    selectLocationField: ".js-position-input",
                    selectOptions: ".js-location-input option",
                    selectOptionsList: ".c-select select + .stylish-select > ul li",
                    selectDefaultOption: ".c-select .js-location-input + .stylish-select > ul li:nth-child(1)",
                    selectLocationDefaultOption: ".c-select .js-position-input + .stylish-select > ul li:nth-child(1)",
                    selectStylishElement: ".c-select .js-location-input + .stylish-select .stylish-select-left .stylish-select-right",
                    selectLocationStylishElement: ".c-select .js-position-input + .stylish-select .stylish-select-left .stylish-select-right",
                    alphaSearchOptionsList: "#divAlphabets .owl-stage .owl-item.active .alphabet",
                    alphaSearchActiveOption: "#divAlphabets .owl-stage .owl-item.active .alphabet.selected",
                    toggleAccordion: ".js-toggle-accordion",
                    banPeople: ".ban-people",
                    expandSearchBottom: ".c-es__bottom",
                    expandSearchTop: ".c-es__top",
                    span: "span",
                    input: "input",
                    expandSearchBottomFields: ".c-es__service, .c-es__location, .c-es__end-date",
                    expandSearchBottomFieldsAtTopContainer: ".c-es__top .c-es__service, .c-es__top .c-es__location, .c-es__top .c-es__end-date",
                    expandSearchBottomFieldsAtBottomContainer: ".c-es__bottom .c-es__service, .c-es__bottom .c-es__location, .c-es__bottom .c-es__end-date",
                    globalSearch: '.global',
                    heroBanner: '.hero-ban',
                    contentWrapper: '.content-wrapper',
                    mainHeading: '.main-heading',
                    clearAllFilterBtn: '.c-filter__clearAll-btn',
                    alphaSelected: '.alphabet[data-alpha-selected="true"]',
                    SSRInput: '.js-ssr-input',
                    SSRIndexId: 'filter-indexid',
                    SSRIndexName: 'filter-indexname',
                    date: {
                        start: ".js-start-date",
                        end: ".js-end-date",
                        startInput: ".js-start-input",
                        endInput: ".js-end-input"
                    },
                    cssClass: {
                        toggleIcons: "toggle_plus toggle_minus",
                        heroBanner: "hero-ban hero-ban-expand",
                        expand: "expand",
                        startDateinvalid: "c-es__start-date-input-invalid",
                        endDateinvalid: "c-es__end-date-input-invalid",
                        globalSearchHeroBanner: "c-gs__hero-banner",
                        globalMainHeading: "c-gs__main-heading",
                    },
                    element: {
                        body: 'body',
                    },
                    page: {
                        events: '.js-events',
                    },
                    data: {
                        alphaSelected: 'data-alpha-selected',
                    },
                }

                function init() {
                    initStartDate();
                    initEndDate();
                    resetInputFields();
                    toggleMobileViewFiltersLayout();
                    toggleMobileViewFiltersLayoutOnResize();
                    insertGlobalSearchSpecificClasses();
                }

                function initStartDate() {
                    var elmDateInput = jQuery(selector.date.startInput);
                    var defaultDate = getDefaultDate(elmDateInput, true);
                    elmDateInput.datepicker({
                        onClose: function (selectedDate, instanceOfDatepicker) {
                            jQuery(selector.date.endInput).datepicker("option", "minDate", selectedDate);
                        },
                        changeMonth: true
                    }).datepicker("setDate", defaultDate);

                    jQuery(selector.date.start).click(function () {
                        elmDateInput.datepicker("show");
                    });
                }

                function initEndDate() {
                    var elmDateInput = jQuery(selector.date.endInput);
                    var defaultDate = getDefaultDate(elmDateInput, false);
                    elmDateInput.datepicker({
                        changeMonth: true
                    }).datepicker("setDate", defaultDate);

                    jQuery(selector.date.end).click(function () {
                        elmDateInput.datepicker("show");
                    });
                }

                function getDefaultDate(innerElm, isStart) {
                    var date = new Date();
                    var isEvents = innerElm.closest(selector.mainContainer).find(selector.eventsContainer).length > 0;

                    if (isStart) {
                        if (isEvents) {
                            return date;
                        }
                        else { // publications or news
                            date.setMonth(date.getMonth() - 3);
                            return date;
                        }
                    }
                    else { // end date
                        if (isEvents) {
                            return '';
                        }
                        else { // publications or news
                            return date;
                        }
                    }
                }

                function resetInputFields() {
                    jQuery(selector.resetContainer).click(function () {
                        resetHandler(this);
                    });
                    jQuery(selector.resetContainer).keypress(function (e) {
                        if (e.keyCode == 32 || e.keyCode == 13) {
                            resetHandler(this);
                        }
                    });
                }
                function resetHandler() {
                    jQuery(selector.extendedSearchContainer).find(selector.inputText).val("");

                    //IT, 02/23: because i can't find a way in the api to clear the current results, I'm just blowing away the DOM elements for them.
                    jQuery('.ui-autocomplete').empty();

                    //Reset invalid Date CSS and Placeholder
                    jQuery(selector.extendedSearchContainer).find(selector.date.startInput).removeClass(selector.cssClass.startDateinvalid);
                    jQuery(selector.extendedSearchContainer).find(selector.date.startInput).attr("placeholder", jQuery(selector.extendedSearchContainer).find(selector.date.startInput).data("placeholder"));

                    jQuery(selector.extendedSearchContainer).find(selector.date.endInput).removeClass(selector.cssClass.endDateinvalid);
                    jQuery(selector.extendedSearchContainer).find(selector.date.endInput).attr("placeholder", jQuery(selector.extendedSearchContainer).find(selector.date.endInput).data("placeholder"));

                    // Reset Location Filter
                    jQuery(selector.extendedSearchContainer).find(selector.selectOptionsList).removeClass('selected');
                    jQuery(selector.extendedSearchContainer).find(selector.selectDefaultOption).addClass('selected');
                    jQuery(selector.extendedSearchContainer).find(selector.selectOptions).removeAttr('selected');
                    jQuery(selector.extendedSearchContainer).find(selector.selectField).prop("selectedIndex", 0);

                    // Reset Position Filter
                    jQuery(selector.extendedSearchContainer).find(selector.selectLocationDefaultOption).addClass('selected');
                    jQuery(selector.extendedSearchContainer).find(selector.selectLocationField).prop("selectedIndex", 0);

                    var locationDefault = jQuery(selector.extendedSearchContainer).find(selector.selectDefaultOption).text();
                    jQuery(selector.extendedSearchContainer).find(selector.selectStylishElement).text(locationDefault);
                    jQuery(selector.extendedSearchContainer).find(selector.selectStylishElement).removeClass('black');

                    var positionDefault = jQuery(selector.extendedSearchContainer).find(selector.selectLocationDefaultOption).text();
                    jQuery(selector.extendedSearchContainer).find(selector.selectLocationStylishElement).text(positionDefault);
                    jQuery(selector.extendedSearchContainer).find(selector.selectLocationStylishElement).removeClass('black');

                    // Reset Last Name Alphabet Search
                    jQuery(selector.extendedSearchContainer).find(selector.alphaSearchActiveOption).removeAttr('selected');
                    jQuery(selector.extendedSearchContainer).find(selector.alphaSearchOptionsList).removeClass('selected');

                    //Reset SSR
                    jQuery(selector.SSRInput).removeAttr(selector.SSRIndexId);
                    jQuery(selector.SSRInput).removeAttr(selector.SSRIndexName);

                    if (jQuery(selector.element.body).find(selector.page.events).length == 1) {
                        searchRedesign.ui.event.filterCheckboxManipulation().clearAllFilters(true);
                    }
                    else {
                        searchRedesign.ui.event.filterCheckboxManipulation().clearAllFilters();
                    }

                    jQuery(selector.extendedSearchContainer).find(selector.alphaSelected).removeAttr(selector.data.alphaSelected);

                    searchRedesign.ui.event.resultsList().refresh(false, true);
                }

                function toggleMobileViewFiltersLayout() {
                    //Desktop
                    if (jQuery(window).width() >= 501) {
                        //Append Fields: Service, Sector or Region , Location , End Date to .c-es__bottom container from being detached in mobile
                        jQuery(selector.expandSearchBottomFields).appendTo(selector.expandSearchBottom);

                        //Detach/Remove Fields: Service, Sector or Region , Location , End Date at .c-es__top container from being appended in mobile
                        jQuery(selector.expandSearchBottomFieldsAtTopContainer).detach();
                    } else {
                        //Mobile
                        if (windowSize !== jQuery(window).width()) {
                            //Append Fields: Service, Sector or Region , Location , End Date to .c-es__top container from being detached in desktop
                            jQuery(selector.expandSearchBottomFields).appendTo(selector.expandSearchTop);

                            //Detach/Remove Fields: Service, Sector or Region , Location , End Date to .c-es__bottom container from being appended in desktop
                            jQuery(selector.expandSearchBottomFieldsAtBottomContainer).detach();
                            windowSize = jQuery(window).width();
                        }
                    }
                };

                function toggleMobileViewFiltersLayoutOnResize() {
                    jQuery(window).on("resize", function () {
                        toggleMobileViewFiltersLayout();
                    });
                };

                function isGlobalSearch() {
                    if (jQuery(selector.element.body).find(selector.globalSearch).length > 0) {
                        return true;
                    }
                };

                function insertGlobalSearchSpecificClasses() {
                    if (isGlobalSearch()) {
                        jQuery(selector.globalSearch).closest(selector.contentWrapper).find(selector.heroBanner).addClass(selector.cssClass.globalSearchHeroBanner);
                        jQuery(selector.mainHeading).addClass(selector.cssClass.globalMainHeading);
                    }
                };

                return {
                    isGlobalSearch: isGlobalSearch,
                    init: init
                }
            },

            eventsTabs: function () {
                var selector = {
                    author: '.js-author-input',
                    tabs: '.js-events-tabs',
                    tabListItem: '.c-tabs__list-item',
                    upcomingTab: '.js-events-upcoming',
                    pastTab: '.js-events-past',
                    upcomingTabId: "a[href='#upcoming-tab']",
                    pastTabId: "a[href='#past-tab']",
                    sortByUpcomingSection: '.js-events .c-results__sort:not(.past)',
                    sortByPastSection: '.js-events .c-results__sort.past',
                    resultsPerPageDesktop: '.js-results__pp',
                    resultsPerPageMobile: '.js-results__pp-mob',
                    clonedresultsPerPageMobile: '.c-cloned__resultspp .js-results__pp-mob',
                    sidePanelSelectedFilters: '.js-events .c-srb__right .c-selected-filter__container:not(.past)',
                    sidePanelSelectedFiltersPast: '.js-events .c-srb__right .c-selected-filter__container.past',
                    appliedFilterDesktopContainer: '.c-selected-filter__container',
                    sidePanelSortByUpcoming: '.js-events .c-srb__right .c-results__sort:not(.past)',
                    sidePanelSortByPast: '.js-events .c-srb__right .c-results__sort.past',
                    sidePanelFiltersUpcoming: '.c-filter__container:not(.past)',
                    sidePanelFiltersPast: '.c-filter__container.past',
                    activeTab: '#eventsTab .ui-state-active',
                    checkboxSelectedFilter: '.c-selected-filter__detach-container:not(.past)',
                    checkboxSelectedFilterPast: '.c-selected-filter__detach-container.past',
                    cssClass: {
                        disabled: "ui-state-disabled"
                    }
                }

                function init() {
                    eventTabsToggle();
                    sortByTabToggle();
                    toggleResultsPerPageVisibility();
                    toggleResultsPerPageVisibilityOnResize();
                    toggleSortBySidePanelVisibilityOnResize();
                };

                function eventTabsToggle() {
                    jQuery(selector.tabs).tabs();
                };

                function sortByTabToggle() {
                    jQuery(selector.sidePanelSortByPast).insertAfter(jQuery(selector.sidePanelSortByUpcoming));
                    jQuery(selector.sidePanelFiltersPast).insertAfter(jQuery(selector.sidePanelFiltersUpcoming));

                    jQuery(selector.upcomingTabId).click(function () {
                        if (jQuery(this).closest(selector.tabListItem).hasClass(selector.cssClass.disabled)) {
                            return; // tab is disabled
                        }
                        searchRedesign.ui.event.toggleDescriptionSwitchControl().toggleDescription('upcomingInput');

                        //Desktop
                        if (jQuery(window).width() >= 501) {
                            jQuery(selector.sidePanelSelectedFilters).show();
                            jQuery(selector.sidePanelSelectedFiltersPast).hide();

                            jQuery(selector.sidePanelSortByUpcoming).show();
                            jQuery(selector.sidePanelSortByPast).hide();

                            jQuery(selector.sidePanelFiltersUpcoming).show();
                            jQuery(selector.sidePanelFiltersPast).hide();
                        } else {
                            jQuery(selector.sidePanelSelectedFilters).hide();

                            jQuery(selector.sidePanelSortByUpcoming).hide();

                            jQuery(selector.sidePanelFiltersUpcoming).show();
                            jQuery(selector.sidePanelFiltersPast).hide();
                            jQuery(selector.sidePanelSelectedFiltersPast).hide();
                        }
                    });

                    jQuery(selector.pastTabId).click(function () {
                        if (jQuery(this).closest(selector.tabListItem).hasClass(selector.cssClass.disabled)) {
                            return; // tab is disabled
                        }
                        searchRedesign.ui.event.toggleDescriptionSwitchControl().toggleDescription('pastInput');

                        //Desktop
                        if (jQuery(window).width() >= 501) {
                            jQuery(selector.sidePanelSortByPast).show();
                            jQuery(selector.sidePanelFiltersPast).show();
                            jQuery(selector.sidePanelSelectedFiltersPast).show();

                            jQuery(selector.sidePanelSelectedFilters).show();
                            jQuery(selector.sidePanelFiltersUpcoming).hide();
                            jQuery(selector.sidePanelSortByUpcoming).hide();
                        } else {
                            jQuery(selector.sidePanelSelectedFilters).hide();
                            jQuery(selector.sidePanelSortByPast).hide();

                            jQuery(selector.sidePanelSortByUpcoming).hide();

                            jQuery(selector.sidePanelFiltersPast).show();
                            jQuery(selector.sidePanelSelectedFiltersPast).hide();
                        }
                    });
                };

                function toggleResultsPerPageVisibility() {
                    if (jQuery(window).width() >= 501) {
                        jQuery(selector.resultsPerPageDesktop).show();
                        jQuery(selector.clonedresultsPerPageMobile).hide();
                        jQuery(selector.resultsPerPageMobile).hide();
                    } else {
                        jQuery(selector.clonedresultsPerPageMobile).show();
                        jQuery(selector.resultsPerPageDesktop).hide();
                    }
                };

                function toggleResultsPerPageVisibilityOnResize() {
                    jQuery(window).on("resize", function () {
                        toggleResultsPerPageVisibility();
                    });
                };

                function toggleSortBySidePanelVisibilityOnResize() {
                    //Toggle visibilty of sort by in events tab on window resize
                    jQuery(window).on("resize", function () {
                        //Upcoming
                        if (jQuery(selector.activeTab).find(selector.upcomingTabId).length == 1) {
                            toggleVisibilityOnResize(window, selector.sidePanelSortByPast, selector.sidePanelSortByUpcoming);
                        }  //Past
                        else {
                            toggleVisibilityOnResize(window, selector.sidePanelSortByUpcoming, selector.sidePanelSortByPast);
                        }
                    });
                };

                function toggleVisibilityOnResize(window, elemToHide, elemToShow) {
                    //Desktop
                    if (jQuery(window).width() >= 501) {
                        jQuery(elemToHide).hide();
                        jQuery(elemToShow).show();
                        jQuery(selector.appliedFilterDesktopContainer).show();
                        //Mobile
                    } else {
                        jQuery(elemToHide).hide();
                        jQuery(elemToHide).hide();
                    }
                };

                function getActiveTab() {
                    var activeTab = jQuery(selector.activeTab).attr('aria-controls');
                    return activeTab;
                };

                return {
                    init: init,
                    getActiveTab: getActiveTab
                }
            },

            toggleDescriptionSwitchControl: function () {
                var selector = {
                    switchInput: '.switch input',
                    resultsDescription: '.c-results__details',
                    eventUpcomingSwitchInput: '.js-events-upcoming .c-left__switch input',
                    eventPastSwitchInput: '.js-events-past .c-left__switch input',
                    newsSwitchInput: '.newsToggle input',
                    pubstSwitchInput: '.pubsToggle input'
                }

                function init() {
                    isInputChecked();
                    descriptionSwitchVisibility();
                };

                function isInputChecked(tabName) {
                    if (typeof (tabName) === 'undefined') {
                        if (jQuery(selector.switchInput).is(':checked')) {
                            return true;
                        }
                    }

                    if (tabName == 'upcomingInput') {
                        return jQuery(selector.eventUpcomingSwitchInput).is(':checked');
                    }

                    if (tabName == 'pastInput') {
                        return jQuery(selector.eventPastSwitchInput).is(':checked');
                    }
                };

                function descriptionSwitchVisibility() {
                    jQuery(selector.switchInput).change(function () {
                        toggleDescription();
                    });

                    jQuery(selector.eventUpcomingSwitchInput).change(function () {
                        toggleDescription('upcomingInput');
                    });

                    jQuery(selector.eventPastSwitchInput).change(function () {
                        toggleDescription('pastInput');
                    });
                };

                function toggleDescription(tabName) {
                    if (isInputChecked(tabName)) {
                        jQuery(selector.resultsDescription).show();
                    } else {
                        jQuery(selector.resultsDescription).hide();
                    }
                }

                return {
                    init: init,
                    toggleDescription: toggleDescription
                }
            },

            resultsList: function () {
                var selector = {
                    mainContainer: '.js-search-container',
                    bodyContainer: ' .js-global, .js-events, .js-news, .js-publications, .js-people',
                    eventHeaderFilter: '.events',
                    pubHeaderFilter: '.publications',
                    rightPanel: '.c-srb__right',
                    inputFields: {
                        keyword: '.js-keyword-input',
                        location: '.js-location-input',
                        ssr: '.js-ssr-input',
                        fromDate: '.js-start-input',
                        toDate: '.js-end-input',
                        author: '.js-author-input',
                        sortBy: '.c-select:visible .js-sortBy-input',
                        sortByEventsUpcoming: '.js-events .c-results__sort:not(.past) .c-select:visible .js-sortBy-input',
                        sortByEventsPast: '.js-events .c-results__sort.past .c-select:visible .js-sortBy-input',
                        numPerPage: '.c-select:visible .js-numPerPage-input',
                        filterType: '.js-filterType-input',
                        filterContainer: '.c-filter__container',
                        filterTypeList: '.c-filter__type-list',
                        searchButton: '.js-button-search',
                        switchToggle: '.switch input',
                        not: {
                            pastFilterType: '.js-filterType-input:not(.past .js-filterType-input):not(.sub-category)',
                            pastFilter: ':not(.past .js-filterType-input)',
                        },
                        filterSubtype: '.js-filterType-input.sub-category',
                        position: '.js-position-input',
                        judge: '.js-judge-input',
                        court: '.js-court-input',
                        school: '.js-school-input',
                    },
                    data: {
                        id: 'id',
                        currentValue: 'current-value',
                        labelTotal: 'label-total',
                        labelContacts: 'label-contacts',
                        titleFilterType: 'title',
                        numPagesPerSection: 'numpages-section',
                        pageNum: 'pagenum',
                        total: 'total',
                        labelNoResult: 'label-no-result',
                        jsonResult: 'json',
                        jsonResultSubcategory: 'json-subcategory',
                        subcategoriesLimit: "subcategories-limit",
                        alphaSelected: 'data-alpha-selected'
                    },
                    attr: {
                        ssrIndexName: 'filter-indexname',
                        ssrIndexValue: 'filter-indexid',
                        disabled: 'disabled',
                        selectedCategories: 'selected-categories',
                        checked: 'checked',
                        origTotal: 'orig-total',
                        selectedBefore: 'selected-before'
                    },
                    gaVariable: {
                        send: "send",
                        event: "event",
                        pubcategory: "Publications",
                        eventcategory: "Event",
                        eventaction: "Click",
                        eventsAction: "Search"
                    },
                    element: {
                        body: 'body',
                    },
                    page: {
                        events: '.js-events',
                        news: '.js-news'
                    },
                    queryString: {
                        name: {
                            relatedResource: "source",
                            category: "category",
                            tag: "content_tag_id",
                            cutoff: "cutoff",
                            peopleMegaMenu: "source",
                            careersAlumni: "param_sitecontentcategory",
                            eventType: "event_type_s"
                        },
                        value: {
                            relatedResource: "relatedresources",
                            peopleMegaMenuVal: "peoplemenusearch",
                            careersAlumniVal: "OUR PEOPLE"
                        }
                    },
                    listContainerGlobal: '.js-global .js-list-container',
                    listContainerEventsUpcomingTab: '.js-events .js-results-tabs .js-events-upcoming',
                    listContainerEventsPastTab: '.js-events .js-results-tabs .js-events-past',
                    listContainerNews: '.js-news .js-list-container',
                    listContainerPublications: '.js-publications .js-list-container',
                    listContainerPerson: '.js-people .js-list-container',
                    tabs: '.js-events-tabs',
                    tabsTitle: '.c-tabs',
                    switchDescription: '.c-left__switch',
                    resultsList: '.js-results-list',
                    facets: '.js-facets',
                    dataContainer: '.js-data-container',
                    loadingIcon: '.js-loading-icon',
                    totalLabel: '.c-results__total',
                    contactsLabel: '.js-label-contacts',
                    resultType: '.js-result-type',
                    paginationContainer: '.c-pagination',
                    pageLink: '.js-pagination-link',
                    noResultFound: '.js-no-result',
                    eventsActiveTab: '.ui-state-active',
                    eventsTabID: '#eventsTab',
                    eventsSortByUpcoming: '.js-events .c-results__sort:not(.past) select.js-sortBy-input', //DROPDOWN
                    eventsSortByPast: '.js-events .c-results__sort.past select.js-sortBy-input',//DROPDOWN
                    eventsResultsPerPage: '.js-results__pp-mob .c-select select.js-numPerPage-input',
                    eventsActiveLink: 'li.ui-tabs-active a',
                    past: '.past',
                    disabled: 'c-filter__type-disabled',
                    filterType: '.c-filter__type',
                    filterContainer: '.c-results__filter',
                    groupedSubtype: '.c-filter__grouped-subtype',
                    groupedSubtypeItem: '.c-filter__grouped-subtype-item',
                    toggleAccordion: '.js-toggle-accordion',
                    alphaSelected: '.alphabet[selected="selected"]',
                    headerPeople: '.c-es__header-people',
                    alphaSearchAlphabet: ".people .c-alpha-search .alphabet",
                    alphaSearchAlphabet: ".people .c-alpha-search .alphabet",
                    selectedFilterSectionLabel: '.c-selected-filter__section-label',
                    not: {
                        past: ':not(.past)',
                    },
                    categories: {
                        category: ".c-filter__checkbox .js-filterType-input",
                        selectedFilter: 'c-selected-filter',
                        containerSubcategories: 'c-filter__subcategory-container',
                        filterSubcategory: 'c-filter__subcategory',
                        filterCheckboxContainer: 'c-filter__checkbox-container',
                        filterCheckbox: 'c-filter__checkbox',
                        filterCheckboxLabel: 'c-filter__checkbox-label',
                        fromGrouped: 'from-grouped', // subcategories from grouped (for OUR PEOPLE subcategories)
                        subcategory: 'sub-category',
                        filterContainerSubtypes: '.c-filter__subcategory-container',
                    },
                    cssClass: {
                        toggleMinus: 'toggle_minus',
                        togglePlus: 'toggle_plus'
                    }
                };

                var names = {
                    enforceLanguage: "enforce_language",
                    languageToEnforce: "language_to_enforce",
                };

                // GLOBAL VARIABLES
                var stayOnTheTopPage = false;
                var hasResultsUpcomingEvent = false;
                var hasResultsPastEvent = false;
                var hasCategoryQS = false;
                var isEventsPast = false;
                var isEventsCurrent = false;

                // I.T. I don't think this is necessary anymore.
                var partialFacetRefresh = { // refresh for grouped subcategory i.e., Our People's office, sector, service & region
                    isActive: false,
                    idCategory: [],
                    idGroup: []
                };

                // I.T. I don't think this is necessary anymore.
                function setPartialFacetRefresh(isActive, idCategory, idGroup) {
                    partialFacetRefresh = {
                        isActive: isActive,
                        idCategory: idCategory,
                        idGroup: idGroup
                    };
                }

                // I.T. I don't think this is necessary anymore.
                function isFullFacetRefresh() {
                    return partialFacetRefresh.isActive == false;
                }

                // determine if the checkbox is a category with groups i.e., "Our People"
                function isCategoryWithGroups(elmCheckbox) {
                    // if the checkbox is a category (not a subcategory)
                    if (!elmCheckbox.hasClass(selector.categories.subcategory)) {
                        // if contains group/s
                        if (elmCheckbox.closest(selector.filterType).find(selector.groupedSubtypeItem).length > 0) {
                            return true;
                        }
                    }

                    return false;
                }

                // check if the category (i.e., "Our People") contains the active group
                // if so, the siblings of this active group will be the one to refresh, not the active group itself
                // also if so, this category facet will be refreshed as well
                function isCategoryWithActiveGroup(elmCategory) {
                    return (partialFacetRefresh.isActive == true && elmCategory.data(selector.data.id) == partialFacetRefresh.idCategory);
                }

                // determine whether there are other categories ticked other than the given category checkbox
                function isThereOtherCategoriesTicked(elmCategoryCheckbox) {
                    var elmSiblings = elmCategoryCheckbox.closest(selector.filterType).siblings();
                    var isThere = false;

                    elmSiblings.each(function () {
                        // check if there is a ticked siblings
                        if (jQuery(this).find(selector.inputFields.filterType).prop(selector.attr.checked) == true) {
                            isThere = true;
                        }
                    });

                    return isThere;
                }

                // determines whether the given group is an active one
                function isActiveGroup(idGroup) {
                    return (partialFacetRefresh.isActive == true && partialFacetRefresh.idGroup == idGroup);
                }

                // determines if the given group is to be refreshed
                function isGroupToRefresh(idCategory, idGroup) {
                    return (partialFacetRefresh.isActive == true && partialFacetRefresh.idCategory == idCategory && partialFacetRefresh.idGroup != idGroup);
                }

                function getInputs(elmContainer, isPagination, isPast) {
                    var elmMainContainer = elmContainer.closest(selector.mainContainer);
                    var filterType = selector.inputFields.not.pastFilterType;

                    if (isPast) {
                        filterType = selector.past + ' ' + selector.inputFields.filterType;
                    }

                    //get all selected type filters
                    var elmFilterTypeList = elmMainContainer.find(filterType).closest(selector.inputFields.filterTypeList);
                    var typeFilters = searchRedesign.ui.event.filterCheckboxManipulation().getCategorySubcategoryFilters(elmFilterTypeList);

                    // pagination
                    var pageNum = 1;
                    if (isPagination) {
                        pageNum = elmMainContainer.find(selector.pageLink + '.selected').data(selector.data.pageNum);
                    }

                    // number per page
                    var elmNumberPerPage = elmMainContainer.find(selector.inputFields.numPerPage);
                    var numberPerPage = elmNumberPerPage.val();
                    if (!numberPerPage) {
                        numberPerPage = elmNumberPerPage.find("option:first").val();
                        if (!numberPerPage) {
                            numberPerPage = 25;
                        }
                    }

                    var enforceLanguage = searchRedesign.ui.event.queryString().get(names.enforceLanguage);
                    if (enforceLanguage && enforceLanguage.length > 0) {
                        enforceLanguage = enforceLanguage.trim();
                    }

                    // check languagetoenforce
                    var languageToEnforce = searchRedesign.ui.event.queryString().get(names.languageToEnforce);
                    if (!!!languageToEnforce && !!document.getElementById('hdnLanguageName')) {
                        languageToEnforce = document.getElementById('hdnLanguageName').value;
                    }
                    if (languageToEnforce && languageToEnforce.length > 0) {
                        languageToEnforce = languageToEnforce.trim();
                    }

                    var data = {
                        keyword: encodeURIComponent(elmMainContainer.find(selector.inputFields.keyword).val()),
                        category: typeFilters.join(';'), // both category and subcategory
                        sortBy: elmMainContainer.find(selector.inputFields.sortBy).val(),
                        pageNum: pageNum ? pageNum : 1,
                        numberPerPage: numberPerPage,
                        numberPerSection: elmMainContainer.find(selector.dataContainer).data(selector.data.numPagesPerSection),
                        enforceLanguage: enforceLanguage,
                        languageToEnforce: languageToEnforce,
                        additionalFilter: []
                    };

                    return data;
                }

                function getInputsForPubsEventsNews(elmContainer, isPagination, isPast) {
                    var data = getInputs(elmContainer, isPagination, isPast);
                    var elmMainContainer = elmContainer.closest(selector.mainContainer);

                    // sector, service & region (ssr)
                    var elmSSR = elmMainContainer.find(selector.inputFields.ssr);
                    var ssr = elmSSR.val();
                    var ssrIndexName = elmSSR.attr(selector.attr.ssrIndexName);
                    var ssrIndexValue = elmSSR.attr(selector.attr.ssrIndexValue);

                    // content tag
                    var tag = searchRedesign.ui.event.queryString().get(selector.queryString.name.tag);

                    //cutoff
                    var isdateolderthancutoff = searchRedesign.ui.event.queryString().get(selector.queryString.name.cutoff);

                    data.location = elmMainContainer.find(selector.inputFields.location).val();
                    data.ssr = (ssr) ? ssr : [];
                    data.ssrIndexName = (ssrIndexName) ? ssrIndexName : [];
                    data.ssrIndexValue = (ssrIndexValue) ? ssrIndexValue : [];
                    data.fromDate = elmMainContainer.find(selector.inputFields.fromDate).val();
                    data.toDate = elmMainContainer.find(selector.inputFields.toDate).val();
                    data.author = elmMainContainer.find(selector.inputFields.author).val();
                    data.tag = (tag) ? tag : []
                    data.cutoff = (isdateolderthancutoff) ? isdateolderthancutoff : true;

                    return data;
                }

                function getInputsForPerson(elmContainer, isPagination, isPast) {
                    var data = getInputs(elmContainer, isPagination, isPast);
                    var elmMainContainer = elmContainer.closest(selector.mainContainer);

                    // sector, service & region (ssr)
                    var elmSSR = elmMainContainer.find(selector.inputFields.ssr);
                    var ssr = elmSSR.val();
                    var ssrIndexName = elmSSR.attr(selector.attr.ssrIndexName);
                    var ssrIndexValue = elmSSR.attr(selector.attr.ssrIndexValue);

                    data.ssr = (ssr) ? ssr : [];
                    data.ssrIndexName = (ssrIndexName) ? ssrIndexName : [];
                    data.ssrIndexValue = (ssrIndexValue) ? ssrIndexValue : [];
                    data.school = elmMainContainer.find(selector.inputFields.school).val();
                    data.position = elmMainContainer.find(selector.inputFields.position).val();
                    data.location = elmMainContainer.find(selector.inputFields.location).val();
                    data.court = elmMainContainer.find(selector.inputFields.court).val();
                    data.judge = elmMainContainer.find(selector.inputFields.judge).val();
                    data.alphaSearch = elmMainContainer.find(selector.alphaSelected).attr("id")
                    if (data.alphaSearch != undefined) {
                        data.alphaSearch = data.alphaSearch[data.alphaSearch.length - 1];
                    }

                    return data;
                }

                function render(ajaxUrl, inputData, elmListContainer, isFacetRefresh, isPastEvent) {
                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    // reset list/values
                    elmListContainer.find(selector.totalLabel).html("<span>");
                    elmListContainer.find(selector.resultsList).remove();
                    elmListContainer.find(selector.loadingIcon).show();
                    elmListContainer.find(selector.switchDescription).hide();

                    //disable all elements inside filter container
                    jQuery(selector.inputFields.filterContainer).find("*").attr(selector.attr.disabled, true);

                    // Scroll Behavior
                    var scrollInMobile = false;
                    var initialQuery;
                    var eventCat;
                    if (stayOnTheTopPage) {
                        initialQuery = stayOnTheTopPage;
                        eventCat = searchRedesign.ui.event.queryString().get(selector.queryString.name.eventType).replace(" ", "").toLowerCase();
                        if (eventCat) {
                            hasCategoryQS = true;
                            inputData.category = eventCat;
                        }
                    }
                    if (!stayOnTheTopPage) {
                        if (jQuery(window).width() >= 501) {
                            jQuery('html, body').animate({
                                scrollTop: elmListContainer.closest(selector.mainContainer).offset().top - 60
                            });
                        } else {
                            var overlayDisplay = jQuery(selector.filterContainer).css("display");
                            if (overlayDisplay != "block") {
                                jQuery('html, body').animate({
                                    scrollTop: elmListContainer.closest(selector.bodyContainer).offset().top - 60
                                }, 1000);
                            }
                        }

                        // exclude category qs when not initial load
                        hasCategoryQS = false;
                    }

                    if ((isEventsCurrent == true && isEventsPast == true) || (isEventsCurrent == false && isEventsPast == false)) {
                        stayOnTheTopPage = false; // not initial page load anymore
                    }

                    jQuery.ajax({
                        url: ajaxUrl,
                        data: inputData,
                        type: "GET",
                        success: function (data) {
                            if (data && data.trim().length > 0) {
                                var elmMainContainer = elmListContainer.closest(selector.mainContainer);

                                // hide loading icon
                                elmListContainer.find(selector.loadingIcon).hide();

                                //show/hide results description depending on the switch slider value set upon clicking search
                                if (elmMainContainer.find(selector.eventsTabID).length == 0) {
                                    if (elmMainContainer.find(selector.inputFields.switchToggle).is(':checked') == false) {
                                        data = data.replace(/class\=\"c-results__details\"/g, 'class="c-results__details" style="display:none" ');
                                    }
                                } else {
                                    if (jQuery(elmMainContainer.find(selector.eventsActiveTab).find('a').attr('href')).find(selector.inputFields.switchToggle).is(':checked') == false) {
                                        data = data.replace(/class\=\"c-results__details\"/g, 'class="c-results__details" style="display:none" ');
                                    }
                                }

                                // append result listing data
                                elmListContainer.append(data);

                                // get elm data container
                                var elmLabelData = elmListContainer.find(selector.dataContainer);

                                // display total number of result
                                var totalNum = jQuery(data).data(selector.data.total);

                                if (totalNum > 0) {
                                    var labelTotal = elmLabelData.data(selector.data.labelTotal).replace("{0}", totalNum);
                                    elmListContainer.find(selector.totalLabel).html("<span>" + labelTotal + "</span>");
                                    elmListContainer.find(selector.switchDescription).show();
                                    //Mobile
                                    if (jQuery(window).width() <= 500) {
                                        elmListContainer.find(selector.switchDescription).css('display', 'flex');
                                    }
                                }
                                else { // no result found
                                    var msg = elmLabelData.data(selector.data.labelNoResult);
                                    elmListContainer.find(selector.noResultFound).html(msg);
                                }

                                jQuery(window).resize(function () {
                                    if (totalNum > 0) {
                                        //Desktop
                                        if (jQuery(window).width() >= 501) {
                                            elmListContainer.find(selector.switchDescription).css('display', 'block');
                                        } else {
                                            //Mobile
                                            elmListContainer.find(selector.switchDescription).css('display', 'flex');
                                        }
                                    }
                                });

                                // refresh tabs for events
                                refreshEventsTabs(elmListContainer, totalNum);

                                // set filter type
                                elmListContainer.find(selector.resultType).each(function (i) {
                                    var elm = jQuery(this);
                                    var idType = elm.text().toLowerCase();
                                    var title = elmMainContainer.find(selector.inputFields.filterType + "[value='" + idType + "']").data(selector.data.titleFilterType);
                                    if (title && title.length > 0) {
                                        elm.text(title);
                                    }

                                    elm.show();
                                });

                                // set label for contacts/authors
                                var labelContacts = elmLabelData.data(selector.data.labelContacts);
                                elmListContainer.find(selector.contactsLabel).html("<span>" + labelContacts + "</span>");

                                // refresh categories and subcategories
                                refreshCategoriesSubcategories(elmListContainer, isFacetRefresh, isPastEvent);

                                // event listener for keyboard tabbing on filter subcategories on people search page
                                jQuery(".c-filter__subcategory").on("keypress", function (e) {
                                    var keycode = e.keyCode ? e.keyCode : e.which;
                                    if (keycode == 13 || keycode == 32) {
                                        e.stopPropagation();
                                        var selected = jQuery(this).find(".js-filterType-input");
                                        selected.click();
                                    }
                                });

                                attachEventHandlerForPagination(elmListContainer);

                                //enable all elements inside filter container
                                jQuery(selector.inputFields.filterContainer).find("*").attr(selector.attr.disabled, false);

                                //this fixes the back button not populating the checkboxes and rectangle filter label.. Only on publication page
                                if (inputData.category.length > 0 && jQuery("div:has('" + selector.page.news + "')").length > 0) {
                                    var filterTypes = jQuery(selector.filterType);
                                    var checkedCats;
                                    if (inputData.category.indexOf(';') > 0) {
                                        checkedCats = jQuery(inputData.category.split(';'));
                                    } else {
                                        checkedCats = jQuery(inputData.category.split(','));
                                    }
                                    if ((filterTypes.length) != (checkedCats.length)) {
                                        searchRedesign.ui.event.filterCheckboxManipulation().isInputChecked().checkCategories(checkedCats);
                                    }
                                }
                                else if (inputData.category.length > 0 && jQuery("div:has('" + selector.pubHeaderFilter + "')").length > 0) {
                                    var checkedCats = jQuery(inputData.category.split(';'));
                                    searchRedesign.ui.event.filterCheckboxManipulation().isInputChecked().checkCategories(checkedCats);
                                }
                                //select category based from the page QS
                                if (hasCategoryQS) {
                                    checkQueryStringForFilterType(isPastEvent, eventCat)
                                    renderCategoryQS(selector.mainContainer);
                                }

                            }
                            else {
                                callbackOnFail("Empty return data.", elmListContainer);
                            }
                        },
                        error: function (msg) {
                            callbackOnFail(msg, elmListContainer);
                        }
                    });
                }

                function callbackOnFail(msg, elmListContainer) {
                    console.log(msg);
                    elmListContainer.find(selector.loadingIcon).hide();
                }

                // refresh categories and subcategories
                function refreshCategoriesSubcategories(elmListContainer, isFacetRefresh, isPastEvent) {
                    var filterContainer = selector.inputFields.filterType + selector.inputFields.not.pastFilter;

                    if (isPastEvent) {
                        filterContainer = selector.past + ' ' + selector.inputFields.filterType;
                    }

                    if (isFacetRefresh) {
                        var facetJson = elmListContainer.find(selector.facets).data(selector.data.jsonResult);

                        // for each categories
                        jQuery(filterContainer).each(function () {
                            if (jQuery(this).hasClass(selector.categories.subcategory)) { // except subcategory
                                return;
                            }

                            var elmCategory = jQuery(this).closest(selector.filterType);

                            if (isFullFacetRefresh() || isCategoryWithActiveGroup(elmCategory)) {
                                var filterId = this.value;
                                var filterType = jQuery(this).data(selector.data.titleFilterType);
                                var zeroFacet = jQuery(this).parent().next().text(filterType);// + ' ' + '(0)');
                                if (facetJson != null) {
                                    var obj = jQuery.grep(facetJson, function (obj) { return obj.Name === filterId; })[0];

                                    if (obj != null) {
                                        jQuery(this).parent().parent().removeClass(selector.disabled);
                                        jQuery(this).parent().next().text(filterType);// + ' ' + '(' + obj.AggregateCount + ')');
                                        
                                        if (jQuery(this).closest(".c-filter__type-list")[0].classList.contains("single-category")) {
                                            jQuery(this).closest(".c-filter__type").attr("tabindex", "-1");
                                        } else {
                                            jQuery(this).parent().parent().attr("tabindex", "0");
                                        }

                                        // store orig total if is full facet refresh and the category contains group i.e., "Our People"
                                        if (isFullFacetRefresh() && isCategoryWithGroups(jQuery(this))) {
                                            jQuery(this).attr(selector.attr.origTotal, obj.AggregateCount);
                                        }
                                    }
                                    else {
                                        jQuery(this).parent().parent().addClass(selector.disabled);
                                        jQuery(this).parent().parent().attr("tabindex", "-1");
                                        zeroFacet;
                                    }
                                }
                                else {
                                    if (!jQuery(this).find('js-filterType-input')) {
                                        jQuery(this).parent().parent().addClass(selector.disabled);
                                        jQuery(this).parent().parent().attr("tabindex", "-1");
                                        zeroFacet;
                                    }
                                }
                            }
                        });

                        // render subcategories
                        renderFilterSubcategories(elmListContainer);

                        // render show more for subcategories
                        searchRedesign.ui.event.filterCheckboxManipulation().includeShowMoreSubcategories(partialFacetRefresh.idGroup);

                        // reset refresh facet global variable
                        setPartialFacetRefresh(false, [], []);
                    }

                    // set currently selected categories/subcategories as prop
                    var elmFilterTypeList = jQuery(filterContainer).closest(selector.inputFields.filterTypeList);
                    var typeFilters = searchRedesign.ui.event.filterCheckboxManipulation().getCategorySubcategoryFilters(elmFilterTypeList);
                    elmFilterTypeList.attr(selector.attr.selectedCategories, typeFilters.join()); // set current selected checkboxes to data

                    // sync rectangle filters
                    searchRedesign.ui.event.filterCheckboxManipulation().syncRectangleFilters(); // sync recctangular filters with the selected checkboxes
                }

                function renderGlobal(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/globalsearch/search";
                    var elmListContainer = jQuery(selector.listContainerGlobal);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    var data = getInputs(elmListContainer, isPagination);
                    if (!!dataLayer) {
                        dataLayer.push({
                            'keyword': data.keyword,
                            'pageNumber': data.pageNum,
                            'event': 'globalsearch'
                        });
                    }
                    var arrQNamesToRemove = [];
                    var autoSuggestGlobal = searchRedesign.ui.event.queryString().get("source");
                    if (autoSuggestGlobal) {
                        autoSuggestGlobal = autoSuggestGlobal.trim();
                        if (autoSuggestGlobal.length > 0 && autoSuggestGlobal == "globalautosuggest") {
                            stayOnTheTopPage = false;
                            arrQNamesToRemove.push("source");
                        }
                    }

                    if (stayOnTheTopPage) {
                        var category = searchRedesign.ui.event.queryString().get(selector.queryString.name.category);
                        if (category) {
                            category = category.trim();
                            if (category.length > 0 && category != '*') {
                                hasCategoryQS = true;
                            }
                        }
                    }

                    searchRedesign.ui.event.queryString().setUrlRemovedQSParameters(arrQNamesToRemove);
                    render(ajaxUrl, data, elmListContainer, isFacetRefresh);
                }

                function renderEventsUpcoming(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/eventsearch/search";
                    var elmListContainer = jQuery(selector.listContainerEventsUpcomingTab);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    var data = getInputsForPubsEventsNews(elmListContainer, isPagination, false);
                    data.isUpcoming = true;

                    var elmMainContainer = elmListContainer.closest(selector.mainContainer);
                    data.sortBy = elmMainContainer.find(selector.inputFields.sortByEventsUpcoming).val();

                    if (data.category.length == 0) {
                        data.category = initAllFilterTypes(elmMainContainer);
                    }

                    if (isPagination) {
                        var pageNum = elmListContainer.find(selector.pageLink + '.selected').data(selector.data.pageNum);
                        data.pageNum = pageNum ? pageNum : 1;
                    }

                    isEventsCurrent = true;
                    render(ajaxUrl, data, elmListContainer, isFacetRefresh);
                }

                function renderEventsPast(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/eventsearch/search";
                    var elmListContainer = jQuery(selector.listContainerEventsPastTab);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    var data = getInputsForPubsEventsNews(elmListContainer, isPagination, true);
                    data.isUpcoming = false;

                    var elmMainContainer = elmListContainer.closest(selector.mainContainer);
                    data.sortBy = elmMainContainer.find(selector.inputFields.sortByEventsPast).val();

                    if (data.category.length == 0) {
                        data.category = initAllFilterTypes(elmMainContainer, true);
                    }

                    if (isPagination) {
                        var pageNum = elmListContainer.find(selector.pageLink + '.selected').data(selector.data.pageNum);
                        data.pageNum = pageNum ? pageNum : 1;
                    }

                    isEventsPast = true;
                    render(ajaxUrl, data, elmListContainer, isFacetRefresh, true);
                }

                function renderNews(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/newssearch/search";
                    var elmListContainer = jQuery(selector.listContainerNews);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    var data = getInputsForPubsEventsNews(elmListContainer, isPagination, false);
                    var elmMainContainer = elmListContainer.closest(selector.mainContainer);

                    if (data.category.length == 0) {
                        data.category = initAllFilterTypes(elmMainContainer);
                    }

                    render(ajaxUrl, data, elmListContainer, isFacetRefresh);
                }

                function renderPublications(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/publicationsearch/search";
                    var elmListContainer = jQuery(selector.listContainerPublications);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    var data = getInputsForPubsEventsNews(elmListContainer, isPagination, false);
                    render(ajaxUrl, data, elmListContainer, isFacetRefresh);
                }

                function renderPerson(isPagination, isFacetRefresh) {
                    var ajaxUrl = "/api/custom/peoplesearch/search";
                    var elmListContainer = jQuery(selector.listContainerPerson);

                    if (!elmListContainer || elmListContainer.length < 1) {
                        return;
                    }

                    // check if search request comes from People Navigation Menu or Careers Alumni Search
                    var qsPeopleNavigationSearch = searchRedesign.ui.event.queryString().get(selector.queryString.name.peopleMegaMenu);
                    var qsCareersAlumniSearch = searchRedesign.ui.event.queryString().get(selector.queryString.name.careersAlumni);
                    if (qsPeopleNavigationSearch == selector.queryString.value.peopleMegaMenuVal || qsCareersAlumniSearch == selector.queryString.value.careersAlumniVal) {
                        stayOnTheTopPage = false;
                    }

                    var data = getInputsForPerson(elmListContainer, isPagination, false);
                    render(ajaxUrl, data, elmListContainer, isFacetRefresh);
                }

                function renderFilterSubcategories(elmContainer) {
                    var filterSubcategoriesFacet = elmContainer.find(selector.facets).data(selector.data.jsonResultSubcategory);
                    if (filterSubcategoriesFacet == null) {
                        return;
                    }

                    // removal of subcategory containers
                    if (isFullFacetRefresh()) { // usual search or initial search load
                        jQuery(selector.categories.filterContainerSubtypes).remove(); // remove existing subcategory group
                    }
                    else {
                        jQuery(selector.categories.filterContainerSubtypes).each(function () {
                            var elmContainerSubtypes = jQuery(this);

                            // check if facet refresh should be for specific group only for i.e., Our People
                            if (isCategoryWithActiveGroup(elmContainerSubtypes.closest(selector.filterType))) {
                                // those group outside the selected subtype only
                                var idGroup = elmContainerSubtypes.closest(selector.groupedSubtypeItem).data(selector.data.id);
                                if (!isActiveGroup(idGroup)) {
                                    elmContainerSubtypes.remove();
                                }
                            }
                        });
                    }

                    // reconstruct subcategory checkboxes
                    jQuery.each(filterSubcategoriesFacet, function (index) {
                        var name = (filterSubcategoriesFacet[index].Name);
                        var nameSplit = name.split(':');
                        var categoryId = nameSplit[0]; // category id
                        var aggregateCount = (filterSubcategoriesFacet[index].AggregateCount);

                        if (nameSplit.length > 2) { // should be 3 or more
                            var elmCategory = jQuery(selector.filterType + "[data-" + selector.data.id + "='" + categoryId + "']");

                            if (isFullFacetRefresh() || isGroupToRefresh(categoryId, nameSplit[1])) {
                                if (nameSplit.length > 3) { // OUR PEOPLE
                                    var groupedSubcategoriesId = nameSplit[1];
                                    var subcategoryTitle = nameSplit[2];
                                    var subcategoryId = nameSplit[3];

                                    if (isSubcategoryIncludedInLimit(elmCategory, subcategoryId)) {
                                        var elmGroupedSubcategories = elmCategory.find(selector.groupedSubtypeItem + "[data-" + selector.data.id + "='" + groupedSubcategoriesId + "']");
                                        var elmSubcategory = getElmSubcategory(subcategoryId, subcategoryTitle, aggregateCount, elmCategory).addClass(selector.categories.fromGrouped);
                                        elmGroupedSubcategories.append(elmSubcategory);
                                    }
                                }
                                else { // OUR PRACTICE, PUBLICATIONS, EVENTS, NEWS
                                    var subcategoryTitle = nameSplit[1];
                                    var subcategoryId = nameSplit[2];

                                    if (isSubcategoryIncludedInLimit(elmCategory, subcategoryId)) {
                                        var elmSubcategory = getElmSubcategory(subcategoryId, subcategoryTitle, aggregateCount, elmCategory);
                                        elmCategory.find(selector.groupedSubtype).append(elmSubcategory);
                                    }
                                }
                            }
                        }
                    });

                    function isSubcategoryIncludedInLimit(elmCategory, subcategoryId) {
                        var limit = elmCategory.data(selector.data.subcategoriesLimit);

                        if (limit && limit.length > 0) {
                            if (limit.indexOf(subcategoryId) > -1) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }
                    }

                    function getElmSubcategory(subcategoryId, subcategoryTitle, aggregateCount, elmCategory) {
                        var filterSubcategory = jQuery("<div>").addClass(selector.categories.filterSubcategory);
                        var filterCheckbox = jQuery("<div>").addClass(selector.categories.filterCheckbox);
                        $(".c-filter__subcategory").attr("tabindex", "0");
                        var label = jQuery("<label>").addClass(selector.categories.filterCheckboxLabel).html(subcategoryTitle);// + ' (' + aggregateCount + ')');
                        filterCheckbox.html("<input class='js-filterType-input sub-category' type='checkbox' value='" + subcategoryId + "' data-title='" + subcategoryTitle + "' tabindex='-1'>");
                        
                        // if is partial facet refresh
                        if (!isFullFacetRefresh()) {
                            // determine if checkbox needs to be re-ticked
                            var currentlySelectedIDs = elmCategory.closest(selector.inputFields.filterTypeList).attr(selector.attr.selectedCategories);
                            if (currentlySelectedIDs && currentlySelectedIDs.indexOf(subcategoryId) > 0) {
                                var elmInputCheckbox = filterCheckbox.find(selector.inputFields.filterSubtype);
                                searchRedesign.ui.event.filterCheckboxManipulation().checkboxGraphicIsChecked(elmInputCheckbox, true);
                            }
                        }
                        /*START Location Filter check*/
                        // If Location filter is set, check the search filter
                        // --todo: continuing discussion around removing it (in the situation of location -ViewTeam-> search) instead of checking filter
                        /* var elmListContainer = jQuery(selector.listContainerPerson);
                         if (!elmListContainer || elmListContainer.length < 1) {
                             return;
                         }
                         var elmMainContainer = elmListContainer.closest(selector.mainContainer);
                         var qsLocation = elmMainContainer.find(selector.inputFields.location).val();
                         if (subcategoryId == qsLocation) {
                             var elmInputCheckbox = filterCheckbox.find(selector.inputFields.filterSubtype);
                             searchRedesign.ui.event.filterCheckboxManipulation().checkboxGraphicIsChecked(elmInputCheckbox, true);
                         }*/
                        /*END Location Filter check*/

                        filterSubcategory.append(filterCheckbox);
                        filterSubcategory.append(label);
                        return filterSubcategory;
                    }

                    // wrap grouped subcategories into container (for OUR PEOPLE)
                    jQuery(selector.groupedSubtypeItem).each(function () {
                        var idGroup = jQuery(this).data(selector.data.id);

                        if (isActiveGroup(idGroup)) {
                            return;
                        }

                        var elmSubcategories = jQuery(this).find("." + selector.categories.filterSubcategory + "." + selector.categories.fromGrouped);

                        if (elmSubcategories && elmSubcategories.length > 0) {
                            if (elmSubcategories.closest(selector.categories.filterContainerSubtypes).length > 0) {
                                // wrapper already exists
                                return;
                            }

                            var elmContainer = jQuery("<div>").addClass(selector.categories.containerSubcategories).addClass(selector.categories.fromGrouped);
                            elmSubcategories.wrapAll(elmContainer);
                            jQuery(this).show();
                        }
                        else { // grouped subcategory with NO subcategory
                            jQuery(this).hide();
                        }
                    });

                    // wrap subcategories (non-grouped) into container (for OUR PRACTICE, PUBLICATIONS, EVENTS, NEWS)
                    jQuery(selector.filterType).each(function () {
                        var elmSubcategories = jQuery(this).find("." + selector.categories.filterSubcategory + ":not(." + selector.categories.fromGrouped + ")");

                        if (elmSubcategories && elmSubcategories.length > 0) {
                            if (elmSubcategories.closest(selector.categories.filterContainerSubtypes).length > 0) {
                                // wrapper already exists
                                return;
                            }

                            var elmContainer = jQuery("<div>").addClass(selector.categories.containerSubcategories);
                            elmSubcategories.wrapAll(elmContainer);
                        }
                    });

                    if (isFullFacetRefresh()) { // usual search or initial search load
                        jQuery(selector.categories.filterContainerSubtypes + '.' + selector.categories.fromGrouped).hide(); // intial hide of groups
                    }
                    else {
                        jQuery(selector.categories.filterContainerSubtypes).each(function () {
                            var elmContainerSubtypes = jQuery(this);

                            // check if facet refresh should be for specific groups only for i.e., Our People
                            if (isCategoryWithActiveGroup(elmContainerSubtypes.closest(selector.filterType))) {
                                // those group outside the selected subtype only
                                var idGroup = elmContainerSubtypes.closest(selector.groupedSubtypeItem).data(selector.data.id)
                                if (!isActiveGroup(idGroup)) {
                                    elmContainerSubtypes.hide();

                                    // reset accordion icon to initial
                                    var elmToggleAccordion = elmContainerSubtypes.closest(selector.groupedSubtypeItem).find(selector.toggleAccordion);
                                    elmToggleAccordion.removeClass(selector.cssClass.toggleMinus);
                                    elmToggleAccordion.addClass(selector.cssClass.togglePlus);
                                }
                            }
                        });
                    }
                }

                function renderCategoryQS(elmMainContainer) {
                    if (elmMainContainer.length > 0) {
                        var removeCatQS = [];
                        var category = searchRedesign.ui.event.queryString().get(selector.queryString.name.category).toLowerCase();
                        jQuery(elmMainContainer).find(selector.inputFields.not.pastFilterType).each(function () {
                            var elm = jQuery(this);
                            var elmtitle = elm.data('title').toLowerCase();
                            var elmId = elm.val().toLowerCase();

                            if (elmtitle == category) {
                                elm.click();
                            }
                        });

                        removeCatQS.push(selector.queryString.name.category);
                        searchRedesign.ui.event.queryString().setUrlRemovedQSParameters(removeCatQS);
                    }
                }

                function refresh(isPagination, isFacetRefresh) {
                    renderGlobal(isPagination, isFacetRefresh);
                    renderEventsUpcoming(isPagination, isFacetRefresh);
                    renderEventsPast(isPagination, isFacetRefresh);
                    renderNews(isPagination, isFacetRefresh);
                    renderPublications(isPagination, isFacetRefresh);
                    renderPerson(isPagination, isFacetRefresh)
                    hasResultsUpcomingEvent = [];
                    hasResultsPastEvent = [];
                }

                function init() {
                    // check if related resource via url's query string
                    var qsRelatedResource = searchRedesign.ui.event.queryString().get(selector.queryString.name.relatedResource);

                    if (qsRelatedResource == selector.queryString.value.relatedResource) { // from related resource
                        stayOnTheTopPage = false;
                    }
                    else {
                        stayOnTheTopPage = true;
                    }

                    refresh(false, true);

                    // click search button
                    jQuery(selector.inputFields.searchButton).click(function (event) { // SEARCH BUTTON TRIGGER
                        event.preventDefault();

                        if (jQuery(selector.element.body).find(selector.page.events).length == 1) {
                            searchRedesign.ui.event.filterCheckboxManipulation().clearAllFilters(true);
                        }
                        else {
                            searchRedesign.ui.event.filterCheckboxManipulation().clearAllFilters();
                        }

                        sendGASearchEvent(this);
                        refresh(false, true);
                    });

                    // selected dropdown
                    jQuery(selector.eventsSortByUpcoming + ", " + selector.eventsSortByPast + ", " + selector.inputFields.sortBy + ", " + selector.inputFields.numPerPage + ", " + selector.eventsResultsPerPage).each(function (i) {
                        var elm = jQuery(this);
                        attachEventHandlerForDropdown(elm);
                    });

                    // selected category/subcategory
                    jQuery(selector.inputFields.filterContainer).on("change", selector.inputFields.filterType, function () { // CHECKLIST TRIGGER
                        var elmCheckbox = jQuery(this);
                        var isFacetRefresh = false;

                        // if selected checkbox is a subcategory from group from i.e., Our People
                        if (elmCheckbox.closest(selector.categories.filterContainerSubtypes).hasClass(selector.categories.fromGrouped)) {
                            var idCategory = elmCheckbox.closest(selector.filterType).data(selector.data.id);
                            var idGroup = elmCheckbox.closest(selector.groupedSubtypeItem).data(selector.data.id);
                            isFacetRefresh = true;
                            setPartialFacetRefresh(true, idCategory, idGroup);
                        }

                        // if the checkbox is a category with groups i.e., "Our People"
                        if (isCategoryWithGroups(elmCheckbox)) {
                            // if selected/ticked
                            if (elmCheckbox.prop(selector.attr.checked) == true) {
                                // checkbox has been selected/ticked before
                                if (elmCheckbox.prop(selector.attr.selectedBefore) == true) {
                                    setRefresh(); // refresh facet
                                }
                            }
                            else { // if unticked
                                // set attribute as selected before
                                elmCheckbox.prop(selector.attr.selectedBefore, true);

                                // if there is other categories ticked
                                if (isThereOtherCategoriesTicked(elmCheckbox)) {
                                    // change the label total to the original
                                    var filterType = elmCheckbox.data(selector.data.titleFilterType);
                                    var origTotal = elmCheckbox.attr(selector.attr.origTotal);
                                    elmCheckbox.parent().next().text(filterType);// + ' ' + '(' + origTotal + ')');
                                }
                                else { // if this category is the only one ticked
                                    setRefresh(); // refresh facet
                                }
                            }

                            function setRefresh() {
                                var idCategory = elmCheckbox.closest(selector.filterType).data(selector.data.id);
                                isFacetRefresh = true;
                                setPartialFacetRefresh(true, idCategory, []);
                            }
                        }

                        refresh(false, isFacetRefresh);
                    });
                }
                function checkQueryStringForFilterType(isPast, eventCat) {
                    var filterCB = jQuery('.c-filter__container:not(.past)').find('' + selector.categories.category + '').filter(function () {
                        return this.value == eventCat;
                    });
                    var filterPastCB = jQuery('.c-filter__container.past').find('' + selector.categories.category + '').filter(function () {
                        return this.value == eventCat;
                    });
                    if (!isPast) {
                        if (filterCB) {
                            var label = filterCB.data('title');
                            var id = filterCB.prop('value');
                            searchRedesign.ui.event.filterCheckboxManipulation().checkboxGraphicIsChecked(filterCB, true);
                            jQuery(selector.selectedFilterSectionLabel + selector.not.past).after('<div class="c-selected-filter" data-id="' + id + '"><span class="c-selected-filter__label">' + label + '</span><span class="c-selected-filter__close"></span></div>');

                        }
                    }
                    else if (isPast === true) {
                        if (filterPastCB) {
                            var label = filterPastCB.data('title');
                            var id = filterPastCB.prop('value');
                            searchRedesign.ui.event.filterCheckboxManipulation().checkboxGraphicIsChecked(filterPastCB, true);
                            jQuery(selector.past + selector.selectedFilterSectionLabel).after('<div class="c-selected-filter" data-id="' + id + '"><span class="c-selected-filter__label">' + label + '</span><span class="c-selected-filter__close"></span></div>');
                        }
                    }
                }
                function attachEventHandlerForDropdown(elmDropdown) {
                    elmDropdown.data(selector.data.currentValue, elmDropdown.val());

                    elmDropdown.change(function (event) { // DROPDOWN TRIGGER
                        var elm = jQuery(this);

                        if (elm.val() == elm.data(selector.data.currentValue)) {
                            return;
                        }

                        refresh(false, false);
                        elm.data(selector.data.currentValue, elm.val());
                    });
                }

                function refreshEventsTabs(elmListContainer, totalNum) {
                    var elmMainTabs = elmListContainer.closest(selector.tabs);

                    if (elmMainTabs.length < 1) {
                        return;
                    }

                    var elmTabsTitle = elmMainTabs.find(selector.tabsTitle);
                    elmTabsTitle.show();

                    if (elmListContainer.attr('id') == "upcoming-tab") {
                        hasResultsUpcomingEvent = totalNum > 0;
                    }

                    if (elmListContainer.attr('id') == "past-tab") {
                        hasResultsPastEvent = totalNum > 0;
                    }

                    if (hasResultsUpcomingEvent && hasResultsPastEvent) { // both active
                        elmMainTabs.tabs({
                            active: [0, 1],
                            disabled: false
                        });
                    }
                    else {
                        if (hasResultsUpcomingEvent) { // only upcoming is active
                            elmMainTabs.tabs({
                                active: [0],
                                disabled: [1]
                            });
                        }
                        else if (hasResultsPastEvent) { // only past is active
                            elmMainTabs.tabs({
                                active: [1],
                                disabled: [0]
                            });
                        }
                        else { // both are inactive
                            elmTabsTitle.hide();
                        }
                    }
                    var activeTab = jQuery(selector.eventsActiveLink).attr('href');
                    jQuery("a[href='" + activeTab + "']").click();
                }

                function attachEventHandlerForPagination(elmListContainer) {
                    // set event handler for pagination
                    elmListContainer.find(selector.pageLink).click(function (event) {
                        var elm = jQuery(this);

                        if (elm.hasClass("selected")) {
                            return;
                        }

                        event.preventDefault();
                        elm.closest(selector.paginationContainer).find(selector.pageLink).removeClass("selected");
                        elm.addClass("selected");
                        refresh(true, false);
                    });
                }

                function initAllFilterTypes(elmMainContainer, isPast) {
                    var typeFiltersDefault = [];
                    var inputFields = selector.inputFields.not.pastFilterType;

                    if (isPast) {
                        inputFields = selector.past + ' ' + selector.inputFields.filterType;
                    }

                    elmMainContainer.find(inputFields).each(function (i) {
                        typeFiltersDefault.push(jQuery(this).val());
                    });

                    return typeFiltersDefault.join();
                }

                function sendGASearchEvent(elm) {
                    if (jQuery(elm).closest(selector.pubHeaderFilter) !== null && jQuery(elm).closest(selector.pubHeaderFilter).length > 0) {
                        var gaEventLabel = jQuery(elm).closest(selector.pubHeaderFilter).find(selector.inputFields.keyword).val();
                        if (typeof ga !== "undefined") {
                            ga(selector.gaVariable.send, selector.gaVariable.event, selector.gaVariable.pubcategory, selector.gaVariable.eventaction, gaEventLabel);
                        }
                    }

                    else if (jQuery(elm).closest(selector.eventHeaderFilter) !== null && jQuery(elm).closest(selector.eventHeaderFilter).length > 0) {
                        var gaEventLabel = jQuery(elm).closest(selector.eventHeaderFilter).find(selector.inputFields.keyword).val();
                        if (typeof ga !== "undefined") {
                            ga(selector.gaVariable.send, selector.gaVariable.event, selector.gaVariable.eventcategory, selector.gaVariable.eventsAction, gaEventLabel);
                        }
                    }

                    searchDataLayerPush(elm)
                }

                function searchDataLayerPush(elm) {
                    if (!!dataLayer) {
                        dataLayer.push({
                            "author": jQuery(selector.mainContainer).find(selector.inputFields.author).val(),
                            "court": jQuery(selector.mainContainer).find(selector.inputFields.court).val(),
                            "event": "global-search-query",
                            "fromDate": jQuery(selector.mainContainer).find(selector.inputFields.fromDate).val(),
                            "judge": jQuery(selector.mainContainer).find(selector.inputFields.judge).val(),
                            "keyword" : jQuery(selector.mainContainer).find(selector.inputFields.keyword).val(),
                            "location": jQuery(selector.mainContainer).find(selector.inputFields.location).siblings(".stylish-select").find(".styledSelect").text(),
                            "position": jQuery(selector.mainContainer).find(selector.inputFields.position).siblings(".stylish-select").find(".styledSelect").text(),
                            "school": jQuery(selector.mainContainer).find(selector.inputFields.school).val(),
                            "ssr": jQuery(selector.mainContainer).find(selector.inputFields.ssr).val(),
                            "toDate" : jQuery(selector.mainContainer).find(selector.inputFields.toDate).val(),
                        });
                    }
                }

                return {
                    init: init,
                    refresh: refresh,
                    attachEventHandlerForDropdown: attachEventHandlerForDropdown
                }
            },

            mobileSideNavigation: function () {
                var selector = {
                    filterMobileNavigation: '.js-mobile-nav',
                    filterContainer: '.c-results__filter',
                    filterOverlay: '.c-results__filter-overlay',
                    filterApplyBtn: '.c-filter__search-btn',
                    closeMobileFilterIcon: '.c-filter__mobile-nav--opened',
                    bodyContainer: '.js-global, .js-events, .js-news, .js-publications, .js-people',
                    selectedFilterPubs: '.js-publications .c-srb__right .c-selected-filter__container',
                    selectedFilterNews: '.js-news .c-srb__right .c-selected-filter__container',
                    bodyDropdownSelect: '.c-srb__container .c-select',
                    grey: '.grey',
                    cssClass: {
                        grey: 'grey'
                    }
                };

                function init() {
                    filterMobile();
                    removeBodyDropdownGreyClass();
                };

                function filterMobile() {
                    selectedFiltersVisibility();
                    showSideNav();
                    closeSideNav();
                    isOverlayClicked();
                    applyButtonClicked();
                };

                function selectedFiltersVisibility() {
                    //Desktop
                    if (jQuery(window).width() >= 501) {
                        jQuery(selector.selectedFilterPubs).show();
                        jQuery(selector.selectedFilterNews).show();
                    }
                    else {
                        jQuery(selector.selectedFilterPubs).hide();
                        jQuery(selector.selectedFilterNews).hide();
                    }
                };

                function showSideNav() {
                    jQuery(selector.filterMobileNavigation).click(function (e) {
                        showItems();
                    });
                };

                function isOverlayClicked() {
                    jQuery(selector.filterOverlay).click(function () {
                        hideItems();
                    });
                };

                function applyButtonClicked() {
                    jQuery(selector.filterApplyBtn).click(function () {
                        hideItems();
                    });
                };

                function closeSideNav() {
                    jQuery(selector.closeMobileFilterIcon).click(function () {
                        hideItems();
                    });
                };

                function hideSideNavOnResize() {
                    jQuery(window).resize(function () {
                        hideModal();
                        selectedFiltersVisibility();
                    });
                };

                function hideModal() {
                    //Hide container, overlay and animate modal
                    //jQuery(selector.filterContainer).animate({ width: 'toggle', duration: 350, });
                    jQuery(selector.filterContainer).animate({ left: '500px', duration: 350, });
                    jQuery(selector.filterContainer).hide();
                    jQuery(selector.filterOverlay).hide();
                };

                function hideItems() {
                    hideModal();

                    // scroll to results listing
                    jQuery('html, body').animate({
                        scrollTop: jQuery(selector.bodyContainer).offset().top - 60
                    });
                };

                function showItems() {
                    // scroll to top upon modal open
                    jQuery('html, body').animate({ scrollTop: 0 }, "slow");
                    //jQuery(selector.filterContainer).animate({ width: 'toggle', duration: 350, });
                    jQuery(selector.filterContainer).animate({ left: '0', duration: 350, });
                    jQuery(selector.filterContainer).show();
                    //Prevent the modal to scroll horizontally
                    jQuery(selector.filterContainer).css({ 'overflow-x': 'hidden' });
                    jQuery(selector.filterOverlay).show();
                };

                function removeBodyDropdownGreyClass() {
                    jQuery(selector.bodyDropdownSelect).change(function () {
                        jQuery(this).find(selector.grey).removeClass(selector.cssClass.grey);
                    });
                };

                return {
                    init: init
                }
            },

            filterCheckboxManipulation: function () {
                var ignore = true;
                var selector = {
                    checkboxInput: '.c-filter__checkbox input[type="checkbox"]',
                    checkboxSelectedFilter: '.c-selected-filter__detach-container:not(.past)',
                    checkboxSelectedFilterPast: '.c-selected-filter__detach-container.past',
                    selectedFilterClose: ".c-selected-filter__close",
                    selectedFilterLabel: '.c-selected-filter__label',
                    selectedFilterMobileContainer: '.c-selected-filter__mobile-container',
                    checkboxFilterLabel: '.c-filter__checkbox-label',
                    checkboxFilter: '.c-filter__checkbox',
                    checkboxFilterDesktopContainer: '.c-selected-filter__container:not(.past)',
                    selectedFilterDesktopContainer: '.c-selected-filter__container',
                    selectedFilter: '.c-selected-filter',
                    selectedFilterSectionLabel: '.c-selected-filter__section-label',
                    selectedFilterDetachContainer: '.c-selected-filter__detach-container',
                    filterResetAllBtn: '.c-filter__resetAll-btn, .c-filter__clearAll-btn',
                    mobileFilterYourResultsLabel: '.c-mobile__filter-label',
                    clearAllFilterBtn: '.c-filter__clearAll-btn',
                    globalPage: '.global',
                    showMoreLink: ".c-filter__grouped-subtype-link",
                    toggleAccordion: ".js-toggle-accordion",
                    filterMultipleTypes: '.multiple-categories',
                    filterType: '.c-filter__type',
                    filterContainer: '.c-filter__container',
                    filterGroupedSubtype: '.c-filter__grouped-subtype',
                    filterGroupedSubtypeItem: '.c-filter__grouped-subtype-item',
                    filterContainerSubtypes: '.c-filter__subcategory-container',
                    filterSubtype: '.c-filter__subcategory',
                    past: '.past',
                    not: {
                        past: ':not(.past)',
                        pastSelectedFilter: ':not(.past .c-selected-filter)',
                        pastCheckboxFilterLabel: ':not(.past .c-filter__checkbox-label)',
                        pastSelectedFilterClose: ':not(.past .c-selected-filter__close)',
                        pastSelectedFilterLabel: ':not(.past .c-selected-filter__label)',
                        pastSelectedFilterSectionLabel: ':not(.past .c-selected-filter__section-label)',
                        pastSelectedFilterDetachContainer: ':not(.past .c-selected-filter__detach-container)',
                        pastSelectedFilterMobileContainer: ':not(.past .c-selected-filter__mobile-container)',
                        pastSelectedFilterDesktopContainer: ':not(.past .c-selected-filter__container)',
                        pastClearAllFilterBtn: ':not(.past .c-filter__clearAll-btn)',
                        pastCheckboxInput: ':not(.past .c-filter__checkbox)',
                    },
                    cssClass: {
                        toggleIcons: "toggle_plus toggle_minus",
                        showSubcategoriesLink: "c-filter__subcategories-show",
                        showSubcategoriesTopDivider: "u-padding-top-10",
                        expanded: "expanded",
                        canHide: "can-hide",
                        selectedFilter: "c-selected-filter"
                    },
                    element: {
                        body: 'body',
                        input: 'input',
                    },
                    input: {
                        checkbox: 'input[type="checkbox"]',
                    },
                    categories: {
                        category: ".c-filter__checkbox .js-filterType-input:not('.sub-category')",
                        categorySelected: ".c-filter__checkbox .js-filterType-input:not('.sub-category'):checked",
                        subcategorytext: 'sub-category',
                        subcategorylabelclass: 'subcategory-filter-label',
                        subcategorySelected: '.js-filterType-input.sub-category:checked',
                        peoplegroupedselector: '.c-filter__subcategory-container.from-grouped',
                        filterTypeList: '.c-filter__type-list',
                        filterSubcategoryContainer: '.c-filter__subcategory-container',
                    },
                    data: {
                        id: 'id',
                        showMoreSubcategories_Include: 'showmore-subcategories-include',
                        showMoreSubcategories_InitNum: 'showmore-subcategories-init-num',
                        showMoreSubcategories_ShowMoreText: 'showmore-subcategories-showmore-text',
                        showMoreSubcategories_ShowLessText: 'showmore-subcategories-showless-text',
                        permanent: 'permanent'
                    },
                    attr: {
                        text: 'text',
                        title: 'title',
                        href: 'href',
                        selectedCategories: 'selected-categories'
                    },
                    page: {
                        events: '.js-events',
                    },
                    tabs: {
                        upcoming: 'upcoming-tab',
                        past: 'past-tab'
                    },
                }

                function init() {
                    isInputChecked();
                    removeSelectedFiltersByDeleteIcon();
                    moveSelectedFiltersInMobileOnResize();
                    moveSelectedFiltersInMobile();
                    selectedFilterSectionLabelToggle();
                    resetAllSelectedFilter();
                    selectFilterLabelOnClick();
                    selectFilterYourResultsMobileLabelOnClick();
                    toggleExpandSearchFilters();
                    hideGroupedSubcategories();
                    includeShowMoreSubcategories();
                };

                function isInputChecked() {
                    function removeFilterLabel(selectedFilterLabel, toRemoveSelectedFilterCheckboxLabel) {
                        jQuery(selectedFilterLabel).each(function (index, selectedFilterLabelObj) {
                            if (toRemoveSelectedFilterCheckboxLabel == jQuery(selectedFilterLabelObj).text()) {
                                jQuery(selectedFilterLabelObj).parent().remove();
                            }
                        });
                    };

                    function categoryRectangleFilterExist(selectedFilterLabel, categoryFilterLabel, subcategoryFilterLabel) {
                        var doesExist = "false";
                        jQuery(selectedFilterLabel).each(function (index, selectedFilterLabelObj) {
                            if (jQuery(selectedFilterLabelObj).text().startsWith(categoryFilterLabel) && jQuery(selectedFilterLabelObj).text() !== subcategoryFilterLabel) {
                                doesExist = "true";
                            }
                        });
                        return doesExist;
                    }
                    function checkCategories(checkedCats) {
                        $.each(checkedCats, function (i, obj) {
                            var chkbxCategory = jQuery("input[value='" + obj + "']");
                            searchRedesign.ui.event.filterCheckboxManipulation().checkboxGraphicIsChecked(chkbxCategory, true);
                            var elmSelectedCategory = jQuery('div[class="' + selector.categories.selectedFilter + '"][data-id="' + obj + '"]');
                            if (elmSelectedCategory === undefined || elmSelectedCategory.length == 0) {
                                searchRedesign.ui.event.filterCheckboxManipulation().isInputChecked().addRectangleFilterSubcategory(chkbxCategory);
                            }
                        });
                    }
                    function addRectangleFilterSubcategory(subcategoryElm) {
                        var label = subcategoryElm.data('title');
                        var id = subcategoryElm.prop('value');
                        var subcategory = '';
                        if (subcategoryElm.hasClass(selector.categories.subcategorytext)) {
                            // Get Parent Filter Type
                            var parentFilterType = subcategoryElm.closest(selector.filterType);
                            var parentCategory;

                            if (parentFilterType.length > 0) {
                                parentCategory = parentFilterType.find(selector.categories.category);
                            }

                            // Find Parent Category From Parent Filter Type
                            if (parentCategory.length > 0) {
                                // Update Label
                                label = parentCategory.data('title') + " : " + label;

                                // Remove rectangular filter for the parent category
                                var toRemoveSelectedFilterCheckboxLabel = parentCategory.data('title');
                                removeFilterLabel(selector.selectedFilterLabel + selector.not.pastSelectedFilterLabel, toRemoveSelectedFilterCheckboxLabel);
                            }
                            subcategory = selector.categories.subcategorylabelclass;
                        }
                        //check to only add rectangle box if it has not been added.. 
                        var divSelectedFilter = jQuery('div[class="' + selector.cssClass.selectedFilter + '"][data-id="' + id + '"]');
                        if (divSelectedFilter === undefined || divSelectedFilter.length == 0) {
                            jQuery(selector.selectedFilterSectionLabel + selector.not.past).after('<div class="c-selected-filter" data-id="' + id + '"><span class="c-selected-filter__label">' + label + '</span><span class="c-selected-filter__close ' + subcategory + '"></span></div>');
                        }
                    }

                    function removeRectangleFilterSubcategory(subcategoryElm) {
                        var toRemoveSelectedFilterCheckboxLabel = subcategoryElm.data('title');
                        var id = subcategoryElm.prop('value');

                        if (subcategoryElm.hasClass('sub-category')) {
                            // Get Parent Filter Type
                            var parentFilterType = subcategoryElm.closest('.c-filter__type');
                            var parentCategory;

                            if (parentFilterType.length > 0) {
                                parentCategory = parentFilterType.find(".c-filter__checkbox .js-filterType-input:not('.sub-category')");
                            }

                            // Find Parent Category From Parent Filter Type
                            if (parentCategory.length > 0) {
                                // Update Label
                                label = parentCategory.data('title') + " : " + subcategoryElm.data('title');
                                id = parentCategory.prop('value');

                                // Remove rectangular filter for the parent category
                                toRemoveSelectedFilterCheckboxLabel = label;
                                // removeFilterLabel(selector.selectedFilterLabel + selector.not.pastSelectedFilterLabel, toRemoveSelectedFilterCheckboxLabel);

                                if (!(parentFilterType.find(selector.checkboxFilter).hasClass('hide'))) {
                                    var isCategoryRectangleVisible = categoryRectangleFilterExist(selector.selectedFilterLabel + selector.not.pastSelectedFilterLabel, parentCategory.data('title'), label);
                                    if (isCategoryRectangleVisible == "false") {
                                        jQuery(selector.selectedFilterSectionLabel + selector.not.past).after('<div class="c-selected-filter" data-id="' + id + '"><span class="c-selected-filter__label">' + parentCategory.data('title') + '</span><span class="c-selected-filter__close"></span></div>');
                                    }
                                }
                            }
                        }
                        removeFilterLabel(selector.selectedFilterLabel + selector.not.pastSelectedFilterLabel, toRemoveSelectedFilterCheckboxLabel);
                    }

                    jQuery(".c-filter__type-list.multiple-categories " + selector.filterType).on("keypress", function (e) {
                        var keycode = e.keyCode ? e.keyCode : e.which;
                        if (keycode == 13 || keycode == 32) {
                            e.stopPropagation();
                            var selected = jQuery(this).find(selector.input.checkbox);
                            selected.click();
                        }
                    });
              
                    jQuery(selector.filterContainer).on("change", selector.checkboxInput, function () {
                        if (jQuery(this).is(':checked')) {
                            //Change checkbox graphic as checked
                            checkboxGraphicIsChecked(this, true);

                            //Current checkbox label selected
                            // var label = jQuery(this).parent().next().text();
                            var label = jQuery(this).data('title');
                            var id = jQuery(this).prop('value');

                            if (jQuery(this).parents(selector.past).length) { //past
                                //Add rectangle filter selected item with the current label in the side panel
                                jQuery(selector.past + ' ' + selector.selectedFilterSectionLabel).after('<div class="c-selected-filter" data-id="' + id + '"><span class="c-selected-filter__label">' + label + '</span><span class="c-selected-filter__close"></span></div>');
                            }
                            else {
                                //Add rectangle filter selected item with the current label in the side panel
                                addRectangleFilterSubcategory(jQuery(this));
                            }

                            var filterGroupedSubtype = jQuery(this).closest(selector.filterType).find(selector.filterGroupedSubtype);
                            if (filterGroupedSubtype.find(selector.categories.filterSubcategoryContainer).length > 0) {
                                filterGroupedSubtype.slideDown();
                            }

                        }
                        else {
                            //Change checkbox graphic as unchecked
                            checkboxGraphicIsChecked(this, false);

                            var toRemoveSelectedFilterCheckboxLabel = jQuery(this).data('title');

                            // Deselect checked subcategories if parent category is deselected
                            if (!jQuery(this).hasClass(selector.categories.subcategorytext)) {
                                var parentFilterType = jQuery(this).closest(selector.filterType);
                                if (parentFilterType.length) {
                                    jQuery(parentFilterType.find(selector.categories.subcategorySelected)).each(function () {
                                        checkboxGraphicIsChecked(this, false);
                                        removeRectangleFilterSubcategory(jQuery(this));
                                    });
                                }
                            }

                            if (jQuery(this).parents(selector.past).length) {
                                removeFilterLabel(selector.past + ' ' + selector.selectedFilterLabel, toRemoveSelectedFilterCheckboxLabel);
                            }
                            else {
                                removeRectangleFilterSubcategory(jQuery(this));
                            }

                            ///unordered list c-filter__grouped-subtype or the 2nd level subcategories hide
                            if (!jQuery(this).hasClass(selector.categories.subcategorytext)) { // not subcategory
                                if (searchRedesign.ui.event.pageGlobalSearch().isGlobalSearch()) {
                                    jQuery(this).closest(selector.filterType).find(selector.filterGroupedSubtype).slideUp();
                                }
                                if (parentFilterType.length) {
                                    if (parentFilterType.find(selector.toggleAccordion).hasClass('toggle_minus')) {
                                        parentFilterType.find(selector.toggleAccordion).removeClass('toggle_minus');
                                        parentFilterType.find(selector.toggleAccordion).addClass('toggle_plus');
                                    }

                                    parentFilterType.find(selector.categories.peoplegroupedselector).slideUp();
                                }
                            }
                        }

                        selectedFilterSectionLabelToggle();
                    });
                    return {
                        init: init,
                        addRectangleFilterSubcategory: addRectangleFilterSubcategory,
                        removeRectangleFilterSubcategory: removeRectangleFilterSubcategory,
                        checkCategories: checkCategories
                    }
                };

                function selectFilterLabelOnClick() {
                    jQuery(selector.checkboxFilterLabel + selector.not.pastCheckboxFilterLabel).click(function () {
                        var selected = jQuery(this).parent().find(selector.input.checkbox);
                        selected.click();
                    });

                    jQuery(selector.past + ' ' + selector.checkboxFilterLabel).click(function () {
                        var selected = jQuery(this).parent().find(selector.input.checkbox);
                        selected.click();
                    });
                };

                function selectFilterYourResultsMobileLabelOnClick() {
                    jQuery(selector.mobileFilterYourResultsLabel).click(function () {
                        jQuery(this).next().click();
                    });
                };

                function selectedFilterSectionLabelToggle() {
                    //If selected filters exists
                    if (jQuery(selector.selectedFilter + selector.not.pastSelectedFilter).length == 0) {
                        //If none found hide YOUR FILTER(S) Label
                        jQuery(selector.selectedFilterSectionLabel + selector.not.pastSelectedFilterSectionLabel).hide();
                        //If none found hide Clear all filters button
                        jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).hide();
                    }
                    else {
                        //Show YOUR FILTER(S) Label
                        jQuery(selector.selectedFilterSectionLabel + selector.not.pastSelectedFilterSectionLabel).show();

                        //Show Clear all filters button in Desktop
                        if (jQuery(window).width() >= 501) {
                            jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).show();
                            jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).attr('tabindex', 0);
                        } else {
                            //Show Clear all filters button in Mobile
                            jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).show();
                        }
                    }

                    //PAST TABS
                    if (jQuery(selector.past + ' ' + selector.selectedFilter).length == 0) {
                        //If none found hide YOUR FILTER(S) Label
                        jQuery(selector.past + ' ' + selector.selectedFilterSectionLabel).hide();
                        //If none found hide Clear all filters button
                        jQuery(selector.past + ' ' + selector.clearAllFilterBtn).hide();
                    }
                    else {
                        //Show YOUR FILTER(S) Label
                        jQuery(selector.past + ' ' + selector.selectedFilterSectionLabel).show();

                        //Show Clear all filters button in Desktop
                        if (jQuery(window).width() >= 501) {
                            jQuery(selector.past + ' ' + selector.clearAllFilterBtn).show();
                            jQuery(selector.past + ' ' + selector.clearAllFilterBtn).attr('tabindex', 0);
                        } else {
                            //Show Clear all filters button in Mobile
                            jQuery(selector.past + ' ' + selector.clearAllFilterBtn).show();
                        }
                    }
                };

                function resetAllSelectedFilter() {
                    jQuery('body').on('click', selector.filterResetAllBtn, function () {
                        clearAllFilters();

                        if (ignore != true) {
                            //ignore refresh on init to avoid infinite looping
                            //refresh results listing after unchecking the filters
                            searchRedesign.ui.event.resultsList().refresh(false, true);
                        }
                    });

                    jQuery('body').on('keypress', selector.filterResetAllBtn, function (e) {
                        var keycode = e.keyCode ? e.keyCode : e.which;

                        if (keycode == 13 || keycode == 32) {
                            clearAllFilters();

                            if (ignore != true) {
                                searchRedesign.ui.event.resultsList().refresh(false, true);
                            }
                        }
                    });

                    ignore = false;
                };

                function clearAllFilters(ignoreTab) {
                    if ((jQuery(selector.element.body).find(selector.page.events).length == 0) || (ignoreTab == true)) {
                        //Loop each selected filters (checked item in filter checkbox) and if a filter is checked/selected, it would perform click to unselect filter
                        uncheckFiltersandRemoveLabels(selector.selectedFilterDesktopContainer, selector.selectedFilterMobileContainer, selector.checkboxInput, ignoreTab);
                        if (jQuery(selector.disabled).length == 0) {
                            if (searchRedesign.ui.event.pageGlobalSearch().isGlobalSearch()) {
                                jQuery(selector.filterType).find(selector.filterGroupedSubtype).slideUp();
                                jQuery(selector.categories.peoplegroupedselector).slideUp();
                            }
                            if (jQuery(selector.toggleAccordion).hasClass('toggle_minus')) {
                                jQuery(selector.toggleAccordion).removeClass('toggle_minus');
                                jQuery(selector.categories.peoplegroupedselector).slideUp();

                                jQuery(selector.toggleAccordion).addClass('toggle_plus');
                            }
                        }
                    }
                    else {
                        uncheckEventsFilters();
                    }
                };

                function uncheckEventsFilters() {
                    var activeTab = searchRedesign.ui.event.eventsTabs().getActiveTab();

                    if (activeTab == selector.tabs.past) {
                        //past
                        uncheckFiltersandRemoveLabels(selector.selectedFilterDesktopContainer + ' ' + selector.past, selector.past + ' ' + selector.selectedFilterMobileContainer, selector.past + ' ' + selector.checkboxFilter + ' ' + selector.input.checkbox);
                    }
                    else {
                        //current
                        uncheckFiltersandRemoveLabels(selector.selectedFilterDesktopContainer + selector.not.pastSelectedFilterDesktopContainer, selector.selectedFilterMobileContainer + selector.not.pastSelectedFilterMobileContainer, selector.checkboxFilter + selector.not.pastCheckboxInput + ' ' + selector.input.checkbox);
                    }
                };

                function uncheckFiltersandRemoveLabels(selectedFilterDesktopContainer, selectedFilterMobileContainer, chkboxInput, ignoreTab) {
                    //Loop each selected filters (checked item in filter checkbox) and if a filter is checked/selected
                    jQuery(chkboxInput).each(function () {
                        if (jQuery(this).is(':checked')) {
                            //Uncheck the current selected checkbox
                            checkboxGraphicIsChecked(this, false);
                            //Remove the rectangle label filter of the selected checkbox
                            var selectedCheckboxTitle = jQuery(this).data(selector.attr.title);
                            removeLabel(selectedFilterDesktopContainer, selectedFilterMobileContainer, selectedCheckboxTitle);
                        }
                    })

                    //Hide the YOUR FILTER(S) Label and Clear all filter button
                    if ((jQuery(selector.element.body).find(selector.page.events).length == 1) && (ignoreTab != true)) {
                        eventFilterSectionLabelVisibility();
                    }
                    else {
                        filterSectionLabelVisibility();
                    }
                };

                function checkboxGraphicIsChecked(elm, checked) {
                    if (jQuery(elm).data(selector.data.permanent) == true) {
                        return;
                    }

                    if (checked) {
                        //Change checkbox graphic as checked
                        jQuery(elm).parent().css({ 'background-position': 'center -72px' });
                        jQuery(elm).prop('checked', true);
                    }
                    else {
                        //Change checkbox graphic as unchecked
                        jQuery(elm).parent().css({ 'background-position': 'left 0' });
                        jQuery(elm).prop('checked', false);
                    }
                };

                function removeLabel(selectedFilterDesktopContainer, selectedFilterMobileContainer, selectedCheckboxTitle) {
                    //Search inside desktop container which label matches the title attribute of the selected checkbox
                    var labelToRemove = jQuery(selectedFilterDesktopContainer).find(selector.selectedFilterLabel).attr(selector.attr.text, selectedCheckboxTitle);
                    jQuery(labelToRemove).parent().remove();
                    //Search inside mobile container which label matches the title attribute of the selected checkbox
                    var mobileLabelToRemove = jQuery(selectedFilterMobileContainer).find(selector.selectedFilterLabel).attr(selector.attr.text, selectedCheckboxTitle);
                    jQuery(mobileLabelToRemove).parent().remove();
                }

                function filterSectionLabelVisibility() {
                    //If selected filters exists
                    if (jQuery(selector.selectedFilter).length == 0) {
                        //If none found hide YOUR FILTER(S) Label
                        jQuery(selector.selectedFilterSectionLabel).hide();
                        //If none found hide Clear all filters button
                        jQuery(selector.clearAllFilterBtn).hide();
                    }
                    else {
                        //Show YOUR FILTER(S) Label
                        jQuery(selector.selectedFilterSectionLabel).show();
                        //Show Clear all filters button in Desktop and Mobile
                        jQuery(selector.clearAllFilterBtn).show();
                    }
                }

                function eventFilterSectionLabelVisibility() {
                    var activeTab = searchRedesign.ui.event.eventsTabs().getActiveTab();
                    if (activeTab == selector.tabs.past) {
                        //PAST TAB
                        if (jQuery(selector.past + ' ' + selector.selectedFilter).length == 0) {
                            //If none found hide YOUR FILTER(S) Label
                            if (jQuery(window).width() >= 501) {
                                jQuery(selector.past + ' ' + selector.selectedFilterSectionLabel).hide();
                            }
                            else {
                                jQuery(selector.selectedFilterSectionLabel + selector.past).hide();
                            }

                            //If none found hide Clear all filters button
                            jQuery(selector.past + ' ' + selector.clearAllFilterBtn).hide();
                        }
                        else {
                            //Show YOUR FILTER(S) Label
                            jQuery(selector.past + ' ' + selector.selectedFilterSectionLabel).show();
                            //Show Clear all filters button in Desktop and Mobile
                            jQuery(selector.past + ' ' + selector.clearAllFilterBtn).show();
                        }
                    }
                    else {
                        //CURRENT TAB
                        //If selected filters exists
                        if (jQuery(selector.selectedFilter + selector.not.pastSelectedFilter).length == 0) {
                            //If none found hide YOUR FILTER(S) Label
                            jQuery(selector.selectedFilterSectionLabel + selector.not.pastSelectedFilterSectionLabel).hide();
                            //If none found hide Clear all filters button
                            jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).hide();
                        }
                        else {
                            //Show YOUR FILTER(S) Label
                            jQuery(selector.selectedFilterSectionLabel + selector.not.pastSelectedFilterSectionLabel).show();
                            //Show Clear all filters button in Desktop and Mobile
                            jQuery(selector.clearAllFilterBtn + selector.not.pastClearAllFilterBtn).show();
                        }
                    }
                }

                function removeSelectedFiltersByDeleteIcon() {
                    function removeFilterByDelete(src, checkboxFilterLabel, toRemoveSelectedFilterLabel) {
                        var targetIndex = null;
                        //Loop each checkbox label and match the to be removed selected rectangle filter current label and set the index
                        jQuery(checkboxFilterLabel).each(function (index, checkboxObj) {
                            if (toRemoveSelectedFilterLabel == checkboxObj.previousElementSibling.firstElementChild.dataset.title) {
                                targetIndex = index;
                            }
                        });

                        //Toggle or uncheck the matched checkbox label to remove the selection with the matched index
                        //jQuery(jQuery(checkboxFilterLabel)[targetIndex]).parent().find(selector.input.checkbox).click();

                        var sibling = jQuery(jQuery(checkboxFilterLabel)[targetIndex]).siblings('.c-filter__checkbox');
                        if (sibling.length > 0) {
                            sibling.find(selector.input.checkbox).click();
                        }
                    };

                    jQuery(selector.element.body).on("click", selector.selectedFilterClose + selector.not.pastSelectedFilterClose, function () {
                        //Get the to be removed selected rectangle filter current label
                        var toRemoveSelectedFilterLabel = jQuery(this).parent().find(selector.selectedFilterLabel).text();
                        if (jQuery(this).hasClass('subcategory-filter-label')) {
                            var colonIndex = toRemoveSelectedFilterLabel.indexOf(':');
                            toRemoveSelectedFilterLabel = toRemoveSelectedFilterLabel.substring(colonIndex + 1, toRemoveSelectedFilterLabel.length);
                            toRemoveSelectedFilterLabel = toRemoveSelectedFilterLabel.trim();
                        }

                        removeFilterByDelete(this, selector.checkboxFilterLabel + selector.not.pastCheckboxFilterLabel, toRemoveSelectedFilterLabel);
                    });

                    jQuery(selector.element.body).on("click", selector.past + ' ' + selector.selectedFilterClose, function () {
                        //Get the to be removed selected rectangle filter current label
                        var toRemoveSelectedFilterLabel = jQuery(this).parent().find(selector.selectedFilterLabel).text();
                        removeFilterByDelete(this, selector.past + ' ' + selector.checkboxFilterLabel, toRemoveSelectedFilterLabel);
                    });
                };

                function moveSelectedFiltersInMobileOnResize() {
                    jQuery(window).on("resize", function () {
                        moveSelectedFiltersInMobile();
                        selectedFilterSectionLabelToggle();
                    });
                };

                function moveSelectedFiltersInMobile() {
                    jQuery(selector.selectedFilterMobileContainer + selector.not.pastSelectedFilterMobileContainer).empty();
                    jQuery(selector.past + ' ' + selector.selectedFilterMobileContainer).empty();

                    jQuery(selector.selectedFilterDetachContainer + selector.not.pastSelectedFilterDetachContainer).clone().appendTo(selector.selectedFilterMobileContainer + selector.not.pastSelectedFilterMobileContainer);
                    jQuery(selector.selectedFilterDetachContainer + selector.past).clone().appendTo(selector.past + ' ' + selector.selectedFilterMobileContainer);
                };

                function toggleExpandSearchFilters() {
                    jQuery(selector.showMoreLink).click(function (e) {
                        e.preventDefault();
                        jQuery(this).find(selector.toggleAccordion).toggleClass(selector.cssClass.toggleIcons);
                        jQuery(this).parent().find(selector.filterContainerSubtypes).slideToggle();
                    });
                };

                function hideGroupedSubcategories() {
                    jQuery(selector.filterMultipleTypes).find(selector.filterGroupedSubtype).hide();
                };

                function includeShowMoreSubcategories(idGroupExempted) {
                    jQuery(selector.filterContainerSubtypes).each(function (i) {
                        var elmContainerSubcategories = jQuery(this);

                        if (elmContainerSubcategories.find('.' + selector.cssClass.showSubcategoriesLink).length > 0) {
                            // check if already contains show more link
                            return;
                        }

                        if (idGroupExempted && elmContainerSubcategories.closest(selector.filterGroupedSubtypeItem).data(selector.data.id) == idGroupExempted) {
                            // exempt active group
                            return;
                        }

                        var elmCategory = elmContainerSubcategories.closest(selector.filterType);
                        var elmSubcategories = elmContainerSubcategories.find(selector.filterSubtype);
                        var include = elmCategory.data(selector.data.showMoreSubcategories_Include);

                        if (include) {
                            var num = elmCategory.data(selector.data.showMoreSubcategories_InitNum);
                            var total = elmSubcategories.length;

                            if (total > num) { // total should be greater than the init num
                                var numHidden = total - num;
                                var showMoreText = elmCategory.data(selector.data.showMoreSubcategories_ShowMoreText).replace('{0}', numHidden);
                                var showLessText = elmCategory.data(selector.data.showMoreSubcategories_ShowLessText);

                                // hide other subcategories
                                for (var i = num; i < total && i > -1; i++) {
                                    var elmSubcategory = jQuery(elmSubcategories.get(i));
                                    elmSubcategory.addClass(selector.cssClass.canHide).hide();
                                }

                                // check if not exists yet
                                var elmDivider = jQuery("<div>").addClass(selector.cssClass.showSubcategoriesTopDivider);
                                var elmShow = jQuery("<a>").addClass(selector.cssClass.showSubcategoriesLink).text(showMoreText);
                                elmContainerSubcategories.append(elmDivider);
                                elmContainerSubcategories.append(elmShow);
                                $(".c-filter__subcategories-show").attr("tabindex", "0");

                                // attach event handler for show more link
                                function elmShowClickKeypress(element) {
                                    var elmLink = jQuery(element);
                                    var categoryParent = elmLink.parent().parent();

                                    if (elmLink.hasClass(selector.cssClass.expanded)) { // expanded to collapse
                                        elmContainerSubcategories.find('.' + selector.cssClass.canHide).fadeOut(300);
                                        elmLink.text(showMoreText);
                                        elmLink.removeClass(selector.cssClass.expanded);

                                        jQuery('html, body').animate({
                                            scrollTop: elmContainerSubcategories.offset().top - 85
                                        });
                                    }
                                    else { // collapsed to expand
                                        elmSubcategories.fadeIn(500);
                                        elmLink.text(showLessText);
                                        elmLink.addClass(selector.cssClass.expanded);

                                        var list = $(categoryParent).find(".c-filter__subcategory-container .can-hide");
                                        if (!!list && !!list.first()) {
                                            var first = list.first();
                                            first.focus();
                                        }
                                    }
                                }
                                elmShow.click(function (event) {
                                    elmShowClickKeypress(this);
                                });
                                elmShow.keypress(function (event) {
                                    if (event.keyCode == 32 || event.keyCode == 13) {
                                        elmShowClickKeypress(this);
                                        event.stopPropagation();
                                    }
                                });
                            }
                        }
                    });
                }

                // get all combinations of category and subcategory filters
                // sample format: cat1, cat2 {subcat2a1, subcat2a2} {subcat2b1, subcat2b2), cat3, cat4 {subcat4a1, subcat4a2}
                function getCategorySubcategoryFilters(elmFilterContainer) {
                    var allFilters = [];

                    elmFilterContainer.find(selector.categories.categorySelected).each(function (i) { // each selected category
                        var elmCategory = jQuery(this);
                        var filterSet = elmCategory.val();

                        elmCategory.closest(selector.filterType).find(selector.categories.filterSubcategoryContainer).each(function (i) { // group container
                            var elmSubcategoriesContainer = jQuery(this);
                            var listSubcategories = [];

                            elmSubcategoriesContainer.find(selector.categories.subcategorySelected).each(function (i) { // each selected subcategory
                                listSubcategories.push(jQuery(this).val());
                            });

                            if (listSubcategories.length > 0) {
                                filterSet += '{' + listSubcategories.join() + '}';
                            }
                        });

                        allFilters.push(filterSet);
                    });

                    return allFilters;
                }

                function syncRectangleFilters() {
                    // exempt search page with multiple tabs i.e., Events search page
                    if (jQuery(selector.page.events).length > 0) {
                        return;
                    }

                    var selectedFilterIds = []

                    jQuery(selector.categories.filterTypeList).each(function () {
                        selectedFilterIds.push(jQuery(this).attr(selector.attr.selectedCategories));
                    });

                    jQuery(selector.selectedFilter).each(function () {
                        var id = jQuery(this).data(selector.data.id);

                        if (selectedFilterIds.join().indexOf(id) < 0) {
                            jQuery(this).remove();
                        }
                    });
                }

                return {
                    init: init,
                    includeShowMoreSubcategories: includeShowMoreSubcategories,
                    clearAllFilters: clearAllFilters,
                    checkboxGraphicIsChecked: checkboxGraphicIsChecked,
                    getCategorySubcategoryFilters: getCategorySubcategoryFilters,
                    syncRectangleFilters: syncRectangleFilters,
                    isInputChecked: isInputChecked
                }
            },

            autoComplete: function () {
                var selector = {
                    form: '.js-search-container form',
                    inputField: {
                        location: '.js-location-input',
                        ssr: '.js-ssr-input',
                        position: '.js-position-input',
                        judge: '.js-judge-input',
                        court: '.js-court-input',
                        school: '.js-school-input',
                        autocomplete: '.ui-autocomplete-input',
                        author: '.js-author-input'
                    },
                    uiFront: '.ui-front',
                    topHeaderStickyHeader: '#top-header.sticky-header',
                }

                jQuery('.js-ssr-input').on('keydown', function (e) { //detect backspace and delete key
                    if (e.which == 8 || e.which == 46) {
                        jQuery(this).removeAttr('filter-indexid');
                        jQuery(this).removeAttr('filter-indexname');
                    };
                });

                function init() {
                    attachEventHandlers();
                    ClearFields();
                    onScroll();
                    removeAutocomplete();
                }

                function onScroll() {
                    jQuery(window).on('scroll', function () {
                        var scroll = window.scrollY //Modern Way (Chrome, Firefox)
                            || document.documentElement.scrollTop //(Old IE, 6, 7, 8)
                        if (scroll > 300 && jQuery(selector.topHeaderStickyHeader).length > 0) {
                            jQuery(selector.uiFront).hide();
                        }
                    });
                }

                function attachEventHandlers() {
                    jQuery.widget("custom.completelistEvent", jQuery.ui.autocomplete, {
                        _create: function () {
                            this._super();
                            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
                        },
                        _renderMenu: function (ul, items) {
                            var that = this;
                            jQuery.each(items, function (index, item) {
                                var li;
                                item.label = item.FilterName;
                                item.value = item.FilterName;
                                if (item.IsResultsGroup) {
                                    ul.append("<li class='ui-autocomplete-category' disabled>" + decodeURIComponent(item.Title) + "</li>");
                                }
                                else if (!item.IsResultsGroup) {
                                    li = that._renderItemData(ul, item);
                                }
                                if (item.IsGroupLastElement) {
                                    li = that._renderItemData(ul, item);
                                }
                            });
                        },
                        _move: function (direction, event) {
                            console.log("red-search-move")
                        },
                        _resizeMenu: function () {
                            this.menu.element.outerWidth(this.element.parents('.c-es__top').first().outerWidth() - ($(window).outerWidth() > 500 ? 10 : 0));
                        }
                    });

                    jQuery.widget("custom.catcompleteGroupMenu", jQuery.ui.autocomplete, {
                        _create: function () {
                            this._super();
                            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
                        },
                        _renderMenu: function (ul, items) {
                            var that = this,
                                currentCategory = "";
                            jQuery.each(items, function (index, item) {
                                var li;

                                if (item.FilterGroupName != currentCategory) {
                                    ul.append("<li class='ui-autocomplete-category'>" + item.FilterGroupName + "</li>");
                                    currentCategory = item.FilterGroupName;
                                }

                                item.label = item.FilterName;
                                item.value = item.FilterName;
                                li = that._renderItemData(ul, item);
                                if (item.FilterGroupName) {
                                    li.attr("aria-label", item.FilterGroupName + " : " + item.FilterName);
                                }
                            });
                        }
                    })

                    jQuery.widget("custom.catcompletepeople1", jQuery.ui.autocomplete, {
                        _renderMenu: function (ul, items) {
                            var that = this;
                            jQuery.each(items, function (index, item) {
                                var li;
                                item.label = item.DisplayName;
                                item.value = item.DisplayName;
                                li = that._renderItemData(ul, item);
                            });
                        }
                    });

                    jQuery.widget("custom.catcompletepublication", jQuery.ui.autocomplete, {
                        _renderMenu: function (ul, items) {
                            var that = this;
                            jQuery.each(items, function (index, item) {
                                var li;
                                item.label = item.FilterName;
                                item.value = item.FilterName;
                                li = that._renderItemData(ul, item);
                            });
                        },
                        _resizeMenu: function () {
                            this.menu.element.outerWidth(this.element.parents('.c-es__top').first().outerWidth() - ($(window).outerWidth() > 500 ? 10 : 0));
                        }
                    });

                    jQuery.widget("custom.catcompletepress", jQuery.ui.autocomplete, {
                        _renderMenu: function (ul, items) {
                            var that = this;
                            jQuery.each(items, function (index, item) {
                                var li;
                                item.label = item.FilterName;
                                item.value = item.FilterName;
                                li = that._renderItemData(ul, item);
                            });
                        },
                        _resizeMenu: function () {
                            this.menu.element.outerWidth(this.element.parents('.c-es__top').first().outerWidth() - ($(window).outerWidth() > 500 ? 10 : 0));
                        }
                    });

                    jQuery.widget("custom.catcompleteGlobalSearch", jQuery.ui.autocomplete, {
                        _create: function () {
                            this._super();
                            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
                        },
                        _renderMenu: function (ul, items) {
                            var that = this;

                            jQuery.each(items, function (index, item) {
                                var li;
                                if (item.IsResultsGroup) {
                                    ul.append("<li class='ui-autocomplete-category'>" + decodeURIComponent(item.Title) + "</li>");
                                }
                                item.label = item.Title;
                                item.value = item.Title;
                                if (!item.IsResultsGroup) {
                                    li = that._renderItemData(ul, item);
                                }
                                else if (item.IsGroupLastElement) {
                                    li = that._renderItemData(ul, item);
                                }
                            });
                        }
                    });

                    $.widget("custom.completelist", $.ui.autocomplete, {
                        _renderItem: function (ul, item) {
                            return $("<li>")
                                .append("<a>" + item.FilterName + "</a>")
                                .appendTo(ul);
                        }
                    });

                    $.widget("custom.catcompleteGroup", $.ui.autocomplete, {
                        _create: function () {
                            this._super();
                            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
                        },
                        _renderMenu: function (ul, items) {
                            var that = this,
                                currentCategory = "";
                            $.each(items, function (index, item) {
                                var li;
                                if (item.FilterGroupName != currentCategory) {
                                    ul.append("<li class='ui-autocomplete-category'>" + item.FilterGroupName + "</li>");
                                    currentCategory = item.FilterGroupName;
                                }
                                //item.value = item.FilterName;
                                item.label = item.FilterName;
                                li = that._renderItemData(ul, item);
                                if (item.FilterGroupName) {
                                    li.attr("aria-label", item.FilterGroupName + " : " + item.FilterName);
                                }
                            });
                        }
                    });

                    $.widget("custom.completelist", $.ui.autocomplete, {
                        _renderMenu: function (ul, items) {
                            var that = this;
                            $.each(items, function (index, item) {
                                var li;
                                item.label = item.FilterName;
                                item.value = item.FilterName;
                                li = that._renderItemData(ul, item);
                            });
                        }
                    });

                    $.widget("custom.catcompletepeople", $.ui.autocomplete, {
                        _renderMenu: function (ul, items) {
                            $.each(items, function (index, item) {
                                ul.append("<li><a href='" + item.PersonURL + "'>" + item.DisplayName + "</a></li>");
                            });
                        }
                    });

                    function eventInitAllFilterTypes(elmMainContainer) {
                        var typeFiltersDefault = [];
                        elmMainContainer.find('.js-news .js-filterType-input').each(function (i) {
                            typeFiltersDefault.push(jQuery(this).val());
                        });

                        return typeFiltersDefault.join(',');
                    }

                    // SSR Auto-suggest
                    var autoSuggestRedesignSSR = jQuery('.js-ssr-input').autoSuggestMenu({
                        listUrl: "/api/custom/commonfilterlisting/getssrlist",
                        listType: "catcompleteGroupMenu",
                        languageCode: jQuery('.js-ssr-input').attr('data-languagecode')
                    });

                    // Event Keyword Auto-suggest
                    var autoSuggestRedesignEventKeyword = jQuery('.events .js-keyword-input').autoSuggest_event({
                        listUrl: "/api/custom/eventlisting/searchbykeyword",
                        listType: "completelistEvent",
                        languageCode: jQuery('.events .js-keyword-input').attr('data-languagecode')
                    });

                    // Publication Keyword Auto-suggest
                    var autoSuggestRedesignPubKeyword = jQuery('.publications .js-keyword-input').autoSuggest_pub({
                        listUrl: "/api/custom/publicationsearch/typeahead",
                        listType: "catcompletepublication",
                        languageCode: jQuery('.publications .js-keyword-input').attr('data-languagecode')
                    });

                    // News Keyword Auto-suggest
                    var newsOptions = jQuery('.js-search-container');
                    var dataSourceId = eventInitAllFilterTypes(newsOptions);
                    var autoSuggestRedesignNewsKeyword = jQuery('.news .js-keyword-input').autoSuggest_pub({
                        listUrl: "/api/custom/newssearch/getpressautosuggest?dataSourceID=" + dataSourceId,
                        listType: "catcompletepress",
                        languageCode: jQuery('.news .js-keyword-input').attr('data-languagecode')
                    });

                    // Author/Contact Auto-suggest
                    var autoSuggestRedesignAuthor = jQuery('.js-author-input').autoSuggest_pub({
                        listUrl: "/api/custom/biolisting/getautosuggestresult",
                        listType: "catcompletepeople1",
                        languageCode: jQuery('.js-author-input').attr('data-languagecode')
                    });

                    var autoSuggestSsr = $(selector.inputField.ssr).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetAllList",
                        listType: "catcompleteGroup",
                        languageCode: $(selector.inputField.ssr).attr('data-languagecode')
                    });

                    var autoSuggestSchool = $(selector.inputField.school).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetSchoolList",
                        listType: "completelist",
                        languageCode: $(selector.inputField.school).attr('data-languagecode')
                    });

                    var autoSuggestPosition = $(selector.inputField.position).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetPositionList",
                        listType: "completelist",
                        languageCode: $(selector.inputField.position).attr('data-languagecode')
                    });

                    var autoSuggestLocation = $(selector.inputField.location).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetOfficeList",
                        listType: "completelist",
                        languageCode: $(selector.inputField.location).attr('data-languagecode')
                    });

                    var autoSuggestCourt = $(selector.inputField.court).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetCourtList",
                        listType: "completelist",
                        languageCode: $(selector.inputField.court).attr('data-languagecode')
                    });

                    var autoSuggestJudge = $(selector.inputField.judge).autoSuggest({
                        listUrl: "/api/custom/CommonFilterListing/GetjudgeList",
                        listType: "completelist",
                        languageCode: $(selector.inputField.judge).attr('data-languagecode')
                    });

                    var autoSuggest = $('.people .js-keyword-input').autoSuggest({
                        listUrl: "/api/custom/biolisting/getautosuggestresult?isinternalbiorequest=false",
                        listType: "catcompletepeople",
                        languageCode: $('.js-keyword-input').attr('data-languagecode')
                    });

                    // Global Search Keyword Auto-suggest
                    jQuery('.global .js-keyword-input').globalAutoSuggest({
                        itemId: ""
                    });

                    jQuery("a, button").click(function (event) {
                        var autoSuggest = ".ui-autocomplete:visible";
                        if (!jQuery(event.target).closest(autoSuggest).length &&
                            !jQuery(event.target).is(autoSuggest)) {
                            jQuery(autoSuggest).hide();
                        }
                    });
                }

                function ClearFields() {
                    jQuery('.js-button-apply').click(function () {
                        if (jQuery(".js-ssr-input").val() == '' || jQuery(".js-ssr-input").val() == null) {
                            jQuery(".js-ssr-input").removeAttr('filter-indexid');
                            jQuery(".js-ssr-input").removeAttr('filter-indexname');
                        }
                    })
                }

                function removeAutocomplete() {
                    jQuery(selector.form).find(selector.inputField.autocomplete).each(function () {
                        jQuery(this).removeAttr("autocomplete");
                    });
                }

                return {
                    init: init
                }
            },

            validateFromDate: function () {
                var selector = {
                    date: {
                        startDateInput: '.js-start-input', //From
                        endDateInput: ".js-end-input" //To
                    },
                    css: {
                        startDateinvalid: "c-es__start-date-input-invalid",
                        endDateinvalid: "c-es__end-date-input-invalid"
                    }
                }

                var literal = {
                    invalidtext: "Format date MM/DD/YYYY"
                }

                function init() {
                    isInputChanged();
                };

                function isInputChanged() {
                    jQuery(selector.date.startDateInput).change(function () {
                        this.classList.remove(selector.css.startDateinvalid);
                        this.placeholder = jQuery(this).data("placeholder");

                        var formattedDate = formatDate(this.value);

                        if (formattedDate != undefined) {
                            jQuery(selector.date.startDateInput).val(formattedDate);
                        }

                        if (!validateDate(this)) {
                            addInvalidCSSClass(this, selector.css.startDateinvalid);
                        }
                    });

                    jQuery(selector.date.endDateInput).change(function () {
                        this.classList.remove(selector.css.endDateinvalid);
                        this.placeholder = jQuery(this).data("placeholder");

                        var formattedDate = formatDate(this.value);

                        if (formattedDate != undefined) {
                            jQuery(selector.date.endDateInput).val(formattedDate);
                        }

                        if (!validateDate(this)) {
                            addInvalidCSSClass(this, selector.css.endDateinvalid);
                        }
                    });

                    jQuery(selector.date.startDateInput).on('focus', function () {
                        this.classList.remove(selector.css.startDateinvalid);
                        this.placeholder = jQuery(this).data("placeholder");
                    });

                    jQuery(selector.date.endDateInput).on('focus', function () {
                        this.classList.remove(selector.css.endDateinvalid);
                        this.placeholder = jQuery(this).data("placeholder");
                    });
                };

                function formatDate(dateInput) {
                    if (dateInput.indexOf('/') != -1) {
                        var dateFormat = dateInput.split('/');
                        var month = dateFormat[0];
                        var day = dateFormat[1];
                        var year = dateFormat[2];

                        if (day.length == 1) {
                            day = ('0' + day);
                        }

                        if (month.length == 1) {
                            month = ('0' + month);
                        }

                        var formattedDate = month + '/' + day + '/' + year;
                    }
                    return formattedDate;
                };

                function validateDate(date) {
                    var isValid = true;

                    isValid = moment(date.value, 'MM/DD/YYYY', true).isValid();

                    if (date.value == "") {
                        isValid = true;
                    }

                    return isValid;
                };

                function addInvalidCSSClass(i, css) {
                    i.value = "";
                    i.classList.add(css);
                    i.placeholder = jQuery(i).data("placeholder-invalid");
                };

                return {
                    init: init
                }
            },

            sortOptionsManipulation: function () {
                var selector = {
                    categoryCheckbox: ".js-filterType-input",
                    categoryContainer: ".c-filter__type",
                    categoryListContainer: ".c-filter__type-list",
                    sortDropdown: ".js-sortBy-input",
                    sortContainer: ".c-select",
                    option: "option",
                    data: {
                        sortOptions: "sorting"
                    },
                    global: ".js-global"
                }

                // format: [{ name: "<name1>", title: "<title1>" }, { name: "<name2>", title: "<title2>" }]
                function getAllSelectedCategorySortOptions(elmCategoryListContainer) {
                    var sortingOptions = [];

                    elmCategoryListContainer.find(selector.categoryContainer).each(function (i) {
                        var elmCategory = jQuery(this);

                        if (elmCategory.find(selector.categoryCheckbox).prop('checked')) {
                            var rawSorts = elmCategory.data(selector.data.sortOptions);
                            var sorts = getSortOptions(rawSorts);

                            if (sortingOptions.length == 0) {
                                sortingOptions = sorts;
                            }
                            else { // get the common sorting options between existing list and new list
                                sortingOptions = jQuery.grep(sortingOptions, function (existingItem) {
                                    var exist = false;

                                    jQuery.each(sorts, function (i, newItem) { // check if already exist
                                        if (existingItem.name == newItem.name) {
                                            exist = true;
                                        }
                                    });

                                    return exist;
                                });
                            }
                        }
                    });

                    if (sortingOptions.length == 0) {
                        var rawSorts = elmCategoryListContainer.data(selector.data.sortOptions);
                        sortingOptions = getSortOptions(rawSorts);
                    }

                    return sortingOptions;
                }

                // format: [{ name: "<name1>", title: "<title1>" }, { name: "<name2>", title: "<title2>" }]
                function getSortOptions(rawSortOptions) {
                    var sortingOptions = [];

                    if (rawSortOptions && rawSortOptions.length > 0) {
                        var sorts = rawSortOptions.split('|');

                        for (var i = 0; i < sorts.length; i++) {
                            var n = sorts[i].split(':')[0];
                            sortingOptions.push({
                                name: n,
                                title: sorts[i].substring(n.length + 1)
                            });
                        }
                    }

                    return sortingOptions;
                }

                function init() {
                    var elmCategoryCheckbox = jQuery(selector.categoryCheckbox);

                    if (elmCategoryCheckbox.closest(selector.global).length == 0) { // page should be global search
                        return;
                    }

                    elmCategoryCheckbox.change(function (event) { // CHECKLIST TRIGGER
                        var elmCategoryListContainer = jQuery(this).closest(selector.categoryListContainer);
                        var sortingOptions = getAllSelectedCategorySortOptions(elmCategoryListContainer);

                        jQuery(selector.sortDropdown).each(function (i) { // update each sort dropdown
                            var elmDropdown = jQuery(this);
                            var shouldChange = false; // var checking if changes should be made in sort options
                            var elmExistingOptions = elmDropdown.find(selector.option);

                            // check first if they have equal count
                            if (elmExistingOptions.length == sortingOptions.length) {
                                elmExistingOptions.each(function (i) {
                                    var existingName = jQuery(this).prop("value");
                                    var exists = false;

                                    // check if the existing option exists in the new options
                                    jQuery.each(sortingOptions, function (i, option) {
                                        if (option.name == existingName) {
                                            exists = true;
                                        }
                                    });

                                    if (!exists) { // didnt find the option
                                        shouldChange = true;
                                    }
                                });
                            }
                            else {
                                shouldChange = true;
                            }

                            if (shouldChange) { //change sort dropdown
                                var sortContainer = elmDropdown.closest(selector.sortContainer).empty(); // temporarily remove dropdown
                                elmDropdown.empty(); // clear dropdown options

                                jQuery.each(sortingOptions, function (i, option) { // check if already exist
                                    elmDropdown.append(jQuery("<" + selector.option + ">").prop("value", option.name).text(option.title));
                                });

                                elmDropdown.show();
                                sortContainer.append(elmDropdown);
                                elmDropdown.edyCustomSelectBox();

                                // attach event handler
                                searchRedesign.ui.event.resultsList().attachEventHandlerForDropdown(elmDropdown);
                            }
                        });
                    });
                }

                return {
                    init: init
                }
            },

            bioHeaderFilter: function () {
                var selector = {
                    resetContainer: '.people .c-es__reset-container',
                    headerPeople: '.c-es__header-people',
                    mobileBottomContainer: '.c-es__mobile-bottom-container',
                    accordionContainer: '.c-es__show-more-people',
                    accordionToggle: '.js-toggle-accordion',
                    searchFields: '.c-es__search-by-fields',
                    class: {
                        toggle: 'toggle_minus_white toggle_plus_white',
                        selected: 'selected',
                    },
                    data: {
                        showLess: 'show-less',
                        showMore: 'show-more',
                    },
                    elem: {
                        form: 'form',
                        span: 'span',
                        fields: 'input:text, input:password, input:file, select, textarea',
                    },
                    attr: {
                        visible: ':visible',
                    },
                    alphabox: {
                        div: '#divAlphabets',
                        alphaSearch: '.alphaSearch',
                        aalphabet: 'a.alphabet',
                    }
                }

                function renderAlphabox() {
                    var alphaString = '';
                    for (var i = 65; i <= 90; i++) {
                        alphaString = alphaString + "<div><a title='" + String.fromCharCode(i).toUpperCase() + "' class='alphabet alphaActive' id='a" + String.fromCharCode(i).toLowerCase() + "' href='javascript:void(0);'> " + String.fromCharCode(i).toUpperCase() + " </a></div>";
                    }

                    jQuery(selector.alphabox.div).html(alphaString);
                    jQuery(selector.alphabox.alphaSearch).owlCarousel({
                        items: 26,
                        loop: false,
                        autoplay: false,
                        mouseDrag: false,
                        dots: false,
                        nav: true,
                        navText: false,
                        responsive: {
                            0: { items: 3 },
                            320: { items: 5 },
                            480: { items: 10 },
                            640: { items: 15 },
                            768: { items: 26 }
                        }
                    });
                };

                function applyFlexBasis() {
                    jQuery(selector.headerPeople + ' ' + selector.accordionContainer).css({ "flex-basis": "calc(30% - 5px)" });
                    jQuery(selector.headerPeople + ' ' + selector.mobileBottomContainer).css({ "flex-basis": "calc(70% - 15px)" });
                };

                jQuery(document).on('click', selector.alphabox.aalphabet, function () {
                    jQuery(selector.alphabox.aalphabet).removeClass(selector.class.selected);
                    jQuery(selector.alphabox.aalphabet).attr(selector.class.selected, false);
                    jQuery(this).addClass(selector.class.selected);
                    jQuery(this).attr(selector.class.selected, true);
                    searchRedesign.ui.event.filterCheckboxManipulation().clearAllFilters();
                    searchRedesign.ui.event.resultsList().refresh(false, true);
                });

                function resetFields() {
                    jQuery(selector.resetContainer).click(function () {
                        var form = jQuery(this).closest(selector.elem.form);
                        jQuery(form).find(selector.elem.fields).val('');
                    });
                };

                function init() {
                    renderAlphabox();
                    applyFlexBasis();
                    resetFields();
                };

                return {
                    init: init
                }
            },

            bioAccordion: function () {
                var selector = {
                    accordionContainer: '.c-es__show-more-people',
                    accordionToggle: '.js-toggle-accordion',
                    searchFields: '.c-es__search-by-fields',
                    class: {
                        toggle: 'toggle_minus_white toggle_plus_white',
                        selected: 'selected',
                    },
                    data: {
                        showLess: 'show-less',
                        showMore: 'show-more',
                    },
                    elem: {
                        form: 'form',
                        span: 'span',
                        fields: 'input:text, input:password, input:file, select, textarea',
                    },
                    attr: {
                        visible: ':visible',
                    }
                };

                function accordionClickKeypressHandler(element) {
                    var fieldcontainer = jQuery(selector.searchFields);
                    var toggleLabel = jQuery(element).find(selector.elem.span);
                    var animationDuration;

                    jQuery(selector.accordionContainer + ' ' + selector.accordionToggle).toggleClass(selector.class.toggle);

                    jQuery(fieldcontainer).slideToggle({
                        duration: animationDuration,
                        complete: function () {
                            jQuery(toggleLabel).text(jQuery(fieldcontainer).is(selector.attr.visible) ? jQuery(toggleLabel).data(selector.data.showLess) : jQuery(toggleLabel).data(selector.data.showMore));
                        }
                    });
                }

                jQuery(selector.accordionContainer).click(function (e) {
                    if (e.which) {
                        //Actually clicked
                        animationDuration = 500;
                    }
                    else {
                        //Triggered by function
                        animationDuration = 0;
                    }
                    accordionClickKeypressHandler(this);
                });

                jQuery(selector.accordionContainer).keypress(function (e) {
                    if (e.keyCode == 32 || e.keyCode == 13) {
                        if (e.which) {
                            //Actually clicked
                            animationDuration = 500;
                        }
                        else {
                            //Triggered by function
                            animationDuration = 0;
                        }
                        accordionClickKeypressHandler(this);
                    }
                });

                function accordionSlideUp() {
                    var isEmpty = true;
                    jQuery(selector.searchFields).find(selector.elem.fields).each(function () {
                        if (jQuery(this).val() != "") {
                            isEmpty = false;
                        }
                    });

                    if (isEmpty) {
                        jQuery(selector.searchFields).slideUp();
                    }
                    else {
                        var toggleLabel = jQuery(selector.accordionContainer).find(selector.elem.span);
                        jQuery(selector.accordionContainer + ' ' + selector.accordionToggle).toggleClass(selector.class.toggle);
                        jQuery(toggleLabel).text(jQuery(selector.searchFields).is(selector.attr.visible) ? jQuery(toggleLabel).data(selector.data.showLess) : jQuery(toggleLabel).data(selector.data.showMore));
                    }
                }

                function init() {
                    accordionSlideUp();
                };

                return {
                    init: init
                }
            },

            queryString: function () {
                var selector = {
                    author: ".js-author-input",
                    searchContainer: ".js-search-container",
                    keyword: ".js-keyword-input",
                    location: ".js-location-input",
                    school: ".js-school-input",
                    ssr: ".js-ssr-input",
                    sortBy: ".c-select:visible .js-sortBy-input",
                    attr: {
                        ssrIndexName: "filter-indexname",
                        ssrIndexValue: "filter-indexid",
                        ssrPlaceholder: "ssr",
                        defaultValue: "defaultvalue"
                    },
                    startDate: ".js-start-input.c-es__start-date-input",
                    endDate: ".js-end-input.c-es__end-date-input",
                    position: ".js-position-input",
                    legacy: {
                        megaMenu_people_keyword: "#people-search2",
                        megaMenu_people_ssr: "#sector-search2",
                        megaMenu_people_position: "#allPosition2",
                        megaMenu_people_location: "#allOfficeLocation2",
                        megaMenu_people_searchButton: "#btnSearchMain2"
                    },
                    data: {
                        peoplePage: "result-page"
                    },
                    cssClass: {
                        black: "black"
                    },
                    dropdownContainer: ".main",
                    dropdownDisplay: ".styledSelec",
                    peopleMenuSearch: {
                        sourceQS: "source=peoplemenusearch"
                    }
                };

                var names = { // query string names
                    author: "author",
                    keyword: "SearchString",
                    location: "location",
                    ssrIndexName: "index_name", // name of the index field
                    ssrIndexValue: "index_value", // id of the index value
                    ssrIndexValueTitle: "index_value_title", // title of the index value
                    position: "position", // bio position / designation
                    relatedResource: "source=relatedresources",
                    school: "schoolsearchstring",
                    sort: "sortby"
                };

                // a reusable function to get the query string, kindly don't create on your own and use this one
                // return value format:
                //      if no parameter: return [{ name: "query1", value: "value1" }, { name: "query2", value: "value2" }]
                //      if has parameter: return the value
                function get(name) {
                    var qs = window.location.search; // ?queryName1=value1&queryName2=value2
                    var processedArrQs = [];

                    if (qs.indexOf('?') < 0) {
                        if (name) {
                            return '';
                        }
                        else {
                            return processedArrQs;
                        }
                    }

                    var splitQs = qs.split('?')[1].split('&');
                    var i = 0;
                    var values = '';
                    for (i = 0; i < splitQs.length; i++) {
                        var q = splitQs[i];
                        if (q.indexOf('=') > 0) {
                            var splitQ = q.split('=');
                            var _name = decodeURI(splitQ[0]);
                            var _value = decodeURI(splitQ[1]);

                            // if has parameter "name"
                            if (name) {
                                if (_name && name.toLowerCase() == _name.toLowerCase()) {
                                    if (values) {
                                        values = values + "," + _value;
                                    }
                                    else {
                                        values = _value;
                                    }
                                }
                            }
                            else { // no parameter
                                if (_name && _value) {
                                    processedArrQs.push({ name: _name, value: _value });
                                }
                            }
                        }
                    }
                    if (values) {
                        return decodeURI(values);
                    }

                    if (name) { // has 'name' parameter but not found in the current qs
                        return '';
                    }
                    else {
                        return processedArrQs;
                    }
                }

                // set the URL minus the query string parameters
                function setUrlRemovedQSParameters(arrQNamesToRemove) {
                    if (!arrQNamesToRemove || arrQNamesToRemove.length < 1) {
                        return;
                    }

                    var arrQs = get();

                    if (arrQs.length < 1) { // no query string found
                        return;
                    }

                    var processedQs = ''; // minus the arrQNames
                    var hasRemovedQParameter = false; // determine if there is a change in the query string

                    jQuery.each(arrQs, function (index, item) {
                        if (jQuery.inArray(item.name, arrQNamesToRemove) == -1) { // exempt the given query parameters
                            if (processedQs.length > 0) {
                                processedQs += '&';
                            }

                            processedQs += item.name + '=' + item.value;
                        }
                        else {
                            hasRemovedQParameter = true;
                        }
                    });

                    if (hasRemovedQParameter) {
                        var pathName = window.location.pathname;

                        if (processedQs.length > 0) {
                            pathName += '?' + processedQs; // append '?' in query string
                        }

                        window.history.replaceState({}, document.location.pathname, pathName);
                    }
                }

                function init() { // on document ready
                    var elmContainer = jQuery(selector.searchContainer);
                    var arrQNamesToRemove = [];
                    // check keyword qs then input
                    var keyword = get(names.keyword);
                    if (keyword) {
                        keyword = keyword.trim();
                        if (keyword.length > 0 && keyword != '*') {
                            keyword = decodeURIComponent(keyword);
                            if (keyword.indexOf('+') > 0) {
                                keyword = keyword.replace(/\+/g, ' ');
                            }
                            elmContainer.find(selector.keyword).val(keyword); // input
                        }
                        arrQNamesToRemove.push(names.keyword);
                    }

                    // check location qs then input
                    var location = get(names.location);
                    if (location) {
                        location = location.trim();
                        if (location.length > 0 && location != '*') {
                            var elmLocation = elmContainer.find(selector.location);
                            elmLocation.val(location);

                            setTimeout(function () { // delay color black
                                elmLocation.closest(selector.dropdownContainer).find(selector.dropdownDisplay).addClass(selector.cssClass.black);
                            }, 300);
                        }

                        arrQNamesToRemove.push(names.location);
                    }

                    // check ssr qs then input
                    var ssrQrName = get(names.ssrIndexName);
                    var ssrQrValue = get(names.ssrIndexValue);
                    var ssrQrValueTitle = get(names.ssrIndexValueTitle);
                    if (ssrQrName && ssrQrValue) {
                        ssrQrName = ssrQrName.trim();
                        ssrQrValue = ssrQrValue.trim();
                        elmContainer.find(selector.ssr).attr(selector.attr.ssrIndexName, ssrQrName);
                        elmContainer.find(selector.ssr).attr(selector.attr.ssrIndexValue, ssrQrValue);
                        arrQNamesToRemove.push(names.ssrIndexName);
                        arrQNamesToRemove.push(names.ssrIndexValue);
                    }
                    if (ssrQrValueTitle) {
                        ssrQrValueTitle = ssrQrValueTitle.trim().replace(/\%26amp%3B/g, "&");
                        elmContainer.find(selector.ssr).val(decodeURIComponent(ssrQrValueTitle));
                        arrQNamesToRemove.push(names.ssrIndexValueTitle);
                    }

                    // clear the date when there is a querystring
                    if (get().length > 0) {
                        var elmStartDate = elmContainer.find(selector.startDate);
                        var elmEndDate = elmContainer.find(selector.endDate);

                        if (elmStartDate.length > 0) {
                            jQuery.datepicker._clearDate(elmStartDate);
                        }

                        if (elmEndDate.length > 0) {
                            jQuery.datepicker._clearDate(elmEndDate);
                        }
                    }

                    // check postion (bio) qs then input
                    var postion = get(names.position);
                    if (postion) {
                        postion = postion.trim();
                        if (postion.length > 0) {
                            var elmPosition = elmContainer.find(selector.position);
                            elmPosition.val(postion);

                            setTimeout(function () { // delay color black
                                elmPosition.closest(selector.dropdownContainer).find(selector.dropdownDisplay).addClass(selector.cssClass.black);
                            }, 300);
                        }

                        arrQNamesToRemove.push(names.position);
                    }

                    // check schoolsearchstring qs then input
                    var schoolstring = get(names.school);
                    if (schoolstring) {
                        schoolstring = schoolstring.trim();
                        if (schoolstring.length > 0 && schoolstring != '*') {
                            elmContainer.find(selector.school).val(schoolstring); // input
                            arrQNamesToRemove.push(names.school);
                        }
                    }

                    // check for sortby people
                    var sortby = get(names.sort);
                    if (sortby) {
                        sortby = sortby.trim();
                        if (sortby.length > 0) {
                            var sortElm = elmContainer.find(selector.sortBy);
                            if (sortElm.length > 0) {
                                sortElm.val(sortby);
                            }

                            arrQNamesToRemove.push(names.sort);
                        }
                    }
                    var author = get(names.author);
                    if (author) {
                        author = author.trim();
                        if (author.length > 0) {
                            var authorElm = elmContainer.find(selector.author);
                            if (authorElm.length > 0) {
                                authorElm.val(author);
                            }

                        }
                    }

                    // enable the removal of QS for now
                    setUrlRemovedQSParameters(arrQNamesToRemove);
                }

                function initMegaMenuPeople() {
                    jQuery(selector.legacy.megaMenu_people_searchButton).off('click').on('click', function (e) {
                        //e.preventDefault();
                        var elmForm = jQuery(this).closest("form");
                        var arrQs = [];

                        // check keyword
                        var elmKeyword = elmForm.find(selector.legacy.megaMenu_people_keyword);
                        var keyword = elmKeyword.val();
                        if (keyword) {
                            var defaultValue = elmKeyword.attr(selector.attr.defaultValue);
                            keyword = keyword.trim();

                            if (keyword.length > 0 && keyword != '*' && keyword != defaultValue) {
                                arrQs.push(names.keyword + '=' + keyword);
                            }
                        }

                        // check ssr
                        var elmSsr = elmForm.find(selector.legacy.megaMenu_people_ssr);
                        var ssrName = elmSsr.attr(selector.attr.ssrIndexName);
                        var ssrValue = elmSsr.attr(selector.attr.ssrIndexValue);
                        var ssrValueTitle = encodeURIComponent(elmSsr.val());
                        if (ssrValueTitle == encodeURIComponent(elmSsr.data(selector.attr.ssrPlaceholder))) {
                            ssrValueTitle = elmSsr.val();
                        }

                        if (ssrName) {
                            ssrName = ssrName.trim();
                            if (ssrName.length > 0) {
                                arrQs.push(names.ssrIndexName + '=' + ssrName);
                            }
                        }

                        if (ssrValue) {
                            ssrValue = ssrValue.trim();
                            if (ssrValue.length > 0) {
                                arrQs.push(names.ssrIndexValue + '=' + ssrValue);
                            }
                        }

                        if (ssrValueTitle) {
                            var defaultValue = elmSsr.attr(selector.attr.defaultValue);
                            ssrValueTitle = ssrValueTitle.trim();

                            if (ssrValueTitle.length > 0 && ssrValueTitle != '*' && ssrValueTitle != defaultValue) {
                                arrQs.push(names.ssrIndexValueTitle + '=' + ssrValueTitle);
                            }
                        }

                        // check position
                        var position = elmForm.find(selector.legacy.megaMenu_people_position).val();
                        if (position && position.length > 0) {
                            arrQs.push(names.position + '=' + position);
                        }

                        // check location
                        var location = elmForm.find(selector.legacy.megaMenu_people_location).val();
                        if (location && location.length > 0) {
                            arrQs.push(names.location + '=' + location);
                        }

                        // check enforcelanguage

                        // attach people navigation search source
                        arrQs.push(selector.peopleMenuSearch.sourceQS); // source=peoplemenusearch

                        // get redirect page
                        var page = jQuery(this).data(selector.data.peoplePage);

                        // arrange query string
                        if (arrQs.length > 0) { // has input fields
                            var queryString = '?';

                            jQuery.each(arrQs, function (index, item) {
                                if (index > 0) {
                                    queryString += '&';
                                }

                                queryString += item;
                            });

                            window.location = page + queryString;
                        }
                        else { // no input fields
                            window.location = page;
                        }

                        return false;
                    });
                }

                return {
                    init: init,
                    initMegaMenuPeople: initMegaMenuPeople,
                    get: get,
                    setUrlRemovedQSParameters: setUrlRemovedQSParameters
                }
            }
        }
    },

    utility: {}
}

// When the Document Object Model is ready
jQuery(document).ready(function () {
    if (jQuery(".js-search-container").length > 0) {
        "use strict";
        searchRedesign.ui.event.pageGlobalSearch().init();
        searchRedesign.ui.event.eventsTabs().init();
        searchRedesign.ui.event.toggleDescriptionSwitchControl().init();
        searchRedesign.ui.event.mobileSideNavigation().init();
        searchRedesign.ui.event.filterCheckboxManipulation().init();
        searchRedesign.ui.event.autoComplete().init();
        searchRedesign.ui.event.validateFromDate().init();
        searchRedesign.ui.event.sortOptionsManipulation().init();
        searchRedesign.ui.event.bioHeaderFilter().init();
        searchRedesign.ui.event.queryString().init();
        searchRedesign.ui.event.bioAccordion().init();

        setTimeout(function () { // delay
            searchRedesign.ui.event.resultsList().init();
        }, 500);
    }

    searchRedesign.ui.event.queryString().initMegaMenuPeople();
});
